import { DefaultItem } from "@directus/sdk";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { ReactElement, useEffect, useRef, useState } from "react";

import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { AssetType, MetaphoreAssets } from "../../types/assets";

interface Props {
  asset: DefaultItem<MetaphoreAssets>;
  classes?: string;
}

export default function MediaTypeIcon(props: Props): ReactElement {
  const { asset, classes } = props;

  const [type, setType] = useState<string | undefined>();
  const [rewindVideo, setRewindVideo] = useState<number>(0);
  const [isHover, setIsHover] = useState<boolean>(false);
  const videoRef = useRef<any>(null);
  const videoContainerRef = useRef<any>();
  const { directus } = useDirectus();
  const { getFileType } = Asset(directus);

  const limitPlayTime = 2000;

  useEffect(() => {
    (async () => {
      if (asset.image) {
        const file = await getFileType(asset.image);
        setType(file?.type);
      }
    })();
  }, []);

  const handlePlayVideo = (): void => {
    const startTimeLine =
      (limitPlayTime / videoContainerRef.current.offsetWidth / 1000) *
      rewindVideo;

    videoRef.current.currentTime = startTimeLine;
    videoRef.current.play();
  };

  const renderSwitch = (type: string | undefined): ReactElement => {
    switch (type) {
      case AssetType.psd:
        return (
          <FileOpenOutlinedIcon
            style={{ color: "#DD2127" }}
            className={classes}
          />
        );
      case AssetType.pdf:
        return (
          <PictureAsPdfIcon style={{ color: "#DD2127" }} className={classes} />
        );
      case AssetType.png:
      case AssetType.jpg:
      case AssetType.jpeg:
        return (
          <img
            className={classes}
            src={`${getDirectusAssetsPath(directus)}/${
              asset.image
            }?height=250&quality=10`}
            alt={asset.name}
          />
        );
      // case AssetType.mp4:
      //   return <VideoCameraBackOutlinedIcon style={{color: '#DD2127'}} className={classes} />;
      case AssetType.mp4:
        return (
          <div
            ref={videoContainerRef}
            className="relative"
            onMouseMove={(event): void => {
              handlePlayVideo();
              setRewindVideo(event.nativeEvent.offsetX);
            }}
            onMouseOver={(): void => setIsHover(true)}
            onMouseLeave={(): void => setIsHover(false)}
          >
            <div
              style={{ left: rewindVideo, display: isHover ? "block" : "none" }}
              className="absolute top-0 bottom-0 w-[1px] left-[50%] bg-red-700"
            />
            <video
              muted
              preload="auto"
              ref={videoRef}
              onTimeUpdate={() => {
                if (videoRef.current.currentTime > 2) {
                  videoRef.current.pause();
                  // videoRef.current.currentTime = 0;
                }
              }}
              onMouseLeave={() => {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
              }}
              className={`w-full ${classes}`}
              src={`${getDirectusAssetsPath(directus)}/${asset?.image}`}
            />
          </div>
        );
      default:
        return (
          <SearchOffIcon style={{ color: "#DD2127" }} className={classes} />
        );
    }
  };

  return renderSwitch(type);
}
