import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

export interface CreateCategoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactElement;
}

export default function FullAsset(
  props: CreateCategoryDialogProps
): React.ReactElement {
  const { onClose, isOpen, children } = props;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "100%",
          minHeight: "100%",
        },
      }}
    >
      <DialogContent className="text-center">
        <IconButton
          className="bg-white absolute right-[10px] top-[10px] z-10"
          onClick={onClose}
          size="large"
        >
          <CloseIcon className="text-[35px]" />
        </IconButton>
        {children}
      </DialogContent>
    </Dialog>
  );
}
