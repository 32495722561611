import { DefaultItem } from '@directus/sdk';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';

import { MetaphoreAssets } from '../../types/assets';
import MediaTypeIcon from '../media-format/media-type-icon';

import { useCollectionModal } from './hooks/useCollectionModal';

type Props = {
    asset: DefaultItem<MetaphoreAssets>;
    selectedAssets?: string[] | undefined;
    openModal: boolean;
    closeModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAnchorEl?: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
};

export default function CollectionModal(props: Props): React.ReactElement {
    const { asset, selectedAssets, openModal, closeModal, setAnchorEl } = props;
    const { 
        collections, 
        collectionsIds, 
        inputElement,
        setInputElement, 
        setCollectionsIds, 
        handleCreateCollection, 
        handleAddToCollections 
    } = useCollectionModal();
  
    return (
        <Dialog 
            open={openModal} 
            onClose={() => closeModal(false)}
            maxWidth="md"
        >
            <DialogContent className='flex max-h-[500px] max-w-[720px]'>
                <div className='mr-[50px] max-w-[310px]'>
                    <h2 className='text-[24px] text-[#DD2127] mb-15'>Add to collection</h2>
                    <div className='text-center'>
                        <MediaTypeIcon asset={asset} classes="text-[50px] ml-[20px] max-w-[50px] max-h-[50px] object-contain" />
                    </div>
                    <span className='text-[14px] uppercase text-[#4A4A4A]'>{asset.name}</span>
                </div>
                <div className='w-[400px]'>
                    <div className='text-[14px]'>Create new collection</div>
                    <div className='flex mb-[10px] items-center'>
                        <TextField
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputElement(e.target.value)}
                            sx={{'input': { fontSize: '14px' }}}
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="standard"
                            placeholder="Enter collection name"
                            value={inputElement}
                        />
                        <button
                            style={{ opacity: !inputElement.replace(/\s/g, '').length ? '.5' : '1' }}
                            onClick={inputElement.length ? handleCreateCollection : undefined}
                            disabled={!inputElement.replace(/\s/g, '').length} 
                            className='text-[12px] rounded-[15px] cursor-pointer py-[8px] px-[18px] ml-[15px] border-solid border-[1px] border-black'
                        >
                            create
                        </button>
                    </div>
                    <FormGroup>
                        {collections && collections.map(collection => (
                            <FormControlLabel 
                                sx={{
                                    'svg': { fontSize: '20px' },
                                    'span': { fontSize: '14px' }
                                }}
                                key={collection.id}
                                control={
                                    <Checkbox 
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.checked) {
                                                setCollectionsIds((prev) => [...prev, { metaphore_collections_id: parseInt(e.target.id) }]);
                                            } else {
                                                setCollectionsIds((prev) => prev.filter(item => item.metaphore_collections_id != e.target.id));
                                            }
                                        }} 
                                        id={collection.id.toString()} 
                                    />
                                } 
                                label={collection.name} 
                            />
                        ))}
                    </FormGroup>
                    <div className='flex mt-[10px] items-center justify-between'>
                        <div className='text-[14px]'>Adding asset to {collectionsIds.length} Collections</div>
                        <button
                            onClick={() => handleAddToCollections(asset.id, closeModal, setAnchorEl, selectedAssets)}
                            className='text-[12px] rounded-[15px] cursor-pointer py-[8px] px-[18px] ml-[15px] border-solid border-[1px] border-black'
                        >
                            add
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}