import { DefaultItem } from "@directus/sdk";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CompanyLogo from "../../assets/media/logo.svg";
import CardBox from "../../components/card/card-box";
import { useDirectus } from "../../providers/DirectusProvider";
import Collection from "../../services/collection";
import { MetaphoreAssets } from "../../types/assets";
import { MetaphoreCategories } from "../../types/categories";
import { MetaphoreCollections } from "../../types/collections";

import Search from "./parts/search";
import Sidebar from "./parts/sidebar";

export default function PublicUser(): ReactElement {
  const [assets, setAssets] = useState<
    DefaultItem<MetaphoreAssets>[] | undefined | null
  >();
  const [filter, setFilter] = useState<object>({});
  const [currentCollection, setCurrentCollection] = useState<
    MetaphoreCollections | undefined
  >();
  const [collectionId, setSetCollectionId] = useState<string>();
  const [categories, setCategories] = useState<
    MetaphoreCategories[] | undefined
  >();
  const [sorting, setSorting] = useState<string[]>(["-date_created"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();
  const { directus } = useDirectus();
  const { getCollectionById, getCollectionItemsById } = Collection();

  React.useEffect(() => {
    if (id) {
      (async () => {
        try {
          await directus
            ?.items("metaphore_external_links")
            .readOne(id)
            .then(async (link) => {
              if (link) {
                setSetCollectionId(link.collection_id);
                setCurrentCollection(
                  (await getCollectionById(link.collection_id)) ?? undefined
                );
                setAssets(await getCollectionItemsById(link.collection_id));
              }
            });

          const cat = await directus
            ?.items("metaphore_categories")
            .readByQuery({ limit: -1, sort: ["order_index", "name"] });

          setCategories(cat?.data ?? undefined);
        } catch (e) {
          setAssets(undefined);

          alert(
            "We are sorry, this link is not valid. It is either not public or outdated. Please refer to the User, who provided this link to you for further assistance."
          );
        }
      })();
    }
  }, []);

  return (
    <div className="flex min-h-screen min-w-screen bg-companyLightGrey overflow-hidden">
      {assets ? (
        <>
          <Sidebar />
          <main className="flex min-h-screen flex-grow flex-col bg-companyLightGrey p-20">
            <div className="mb-30 flex w-full flex-row justify-between pl-[45px]">
              <div className="flex items-center">
                <div className="pt-5 pb-15 pr-15 border-neutral-400 relative">
                  <Link to="/">
                    <img
                      className="max-h-full"
                      src={CompanyLogo}
                      alt="Company Logo"
                    />
                  </Link>
                  <span className="text-[10px] absolute right-16 bottom-5 font-bold text-gray-400">
                    METAHUB
                  </span>
                </div>

                {/* <img style={{maxWidth: '150px'}} className='max-h-full' src={ExampleLogo} alt='Company Logo' /> */}
              </div>
            </div>
            {collectionId && (
              <div className="flex w-full mb-[25px] gap-[25px]">
                <Search
                  collectionId={collectionId}
                  filter={filter}
                  sorting={sorting}
                  setAssets={setAssets}
                />
              </div>
            )}
            <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex">
              <div className="flex w-full justify-items-center h-fit">
                <h2 className="text-[24px] text-rose-600">
                  {currentCollection?.name}
                  <span className="text-[16px] text-slate-400">
                    {" "}
                    ({assets.length})
                  </span>
                </h2>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setAnchorEl(event.currentTarget)
                  }
                >
                  <LinkIcon style={{ color: open ? "#DD2127" : "" }} />
                </IconButton>
                <Menu
                  className="shadow rounded-[15px]"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    style: {
                      width: "auto",
                      padding: 0,
                      borderRadius: "15px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_EXTERNAL_USER_LINK}/${id}` ??
                          ""
                      );
                    }}
                    sx={{ "&:hover": { color: "#DD2127" } }}
                  >
                    Copy link
                  </MenuItem>
                </Menu>
              </div>
              {assets?.length ? (
                assets?.map((asset) => (
                  <CardBox
                    key={asset.id}
                    asset={asset}
                    offset={0}
                    actions={<div />}
                    categories={categories}
                  />
                ))
              ) : (
                <div>No results</div>
              )}
            </div>
          </main>
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
