import { DefaultItem } from "@directus/sdk";
import {
  Button,
  CircularProgress,
  DialogActions,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import { AssetType, MetaphoreAssets } from "../../types/assets";
import {
  convertFileBytescale,
  downloadInternalFile,
  uploadFileBytescale,
} from "../../utils";
import { Resolution } from "../card/card-box";

export interface CreateCategoryDialogProps {
  asset: DefaultItem<MetaphoreAssets>;
  isOpen: boolean;
  fileType: string;
  resolution: Resolution;
  onClose: () => void;
}

export default function DownloadFile(
  props: CreateCategoryDialogProps
): React.ReactElement {
  const { onClose, isOpen, asset, fileType, resolution } = props;

  const [selectedFormat, setSelectedFormat] = React.useState<string>(fileType);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isCustomResolution, setIsCustomResolution] =
    React.useState<boolean>(false);
  const [customResolution, setCustomResolution] = React.useState<Resolution>();

  const { directus } = useDirectus();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedFormat(event.target.value);
    setIsCustomResolution(false);
  };

  const handleChangeCustomResolution = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedFormat(event.target.value);
    setIsCustomResolution(true);
  };

  React.useEffect(() => {
    setSelectedFormat(fileType);
    setCustomResolution(undefined);
    setIsLoading(false);
    setIsCustomResolution(false);
    setIsError(false);
  }, [isOpen]);

  if (!isOpen) {
    return <></>;
  }

  const handleDownloadFile = (): void => {
    const settings = selectedFormat.split("_");
    const format = settings[0].substr(6);
    const resolution: any = settings[1];
    const cr = resolution === "c";

    if (cr && !customResolution) {
      setIsError(true);
      return;
    }

    if (selectedFormat === fileType) {
      downloadInternalFile(directus, asset.image);
      onClose();
    } else {
      setIsLoading(true);

      //   if (cr && customResolution) {
      //     resolution = customResolution;
      //   }

      uploadFileBytescale({
        apiKey: "public_W142iAcG9KkQMeEKPrMe3eGhBwMZ",
        accountId: "W142iAc",
        requestBody: {
          url: `${getDirectusAssetsPath(directus)}/${asset.image}`,
        },
      }).then(
        (response) => {
          if (response) {
            convertFileBytescale({
              accountId: "W142iAc",
              filePath: response.filePath,
              transformation: "image",
              querystring: {
                transformationParams: [
                  {
                    w: cr ? customResolution?.width : resolution,
                    h: cr ? customResolution?.height : undefined,
                    f: format,
                    fit: "shrink",
                    cm: "rgb",
                  },
                ],
              },
            }).then(
              (blob) => {
                const objectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");

                link.href = objectUrl;
                link.download = `${asset.name}.${format}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setIsLoading(false);
              },
              (error) => console.error(error)
            );
          }

          onClose();
        },
        (error) => console.error(error)
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>Setting for downloading files</DialogTitle>
      <DialogContent className="min-w-[600px] text-center">
        {isLoading ? (
          <CircularProgress disableShrink />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className="text-[13px] text-gray-400">
                      Amount of Assets: 1
                    </Typography>
                    <Typography className="text-[13px] text-gray-400">
                      Filename: {asset.name}
                    </Typography>
                    <Typography className="text-[13px] text-gray-400">
                      Original resolution:{" "}
                      {resolution
                        ? `${resolution.width} x ${resolution.height}`
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">JPEG</TableCell>
                  <TableCell align="right">PNG</TableCell>
                  <TableCell align="right">WebP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Original Resolution
                    <Typography className="text-[13px] text-gray-400">
                      {resolution
                        ? `${resolution.width} x ${resolution.height}`
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.jpeg}
                      checked={selectedFormat === AssetType.jpeg}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.png}
                      checked={selectedFormat === AssetType.png}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.webp}
                      checked={selectedFormat === AssetType.webp}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Social Resolution 1200px
                    <Typography className="text-[13px] text-gray-400">
                      8bit, sRGB, 72 DPi
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1200}
                      value={AssetType.jpeg + "_1200"}
                      checked={selectedFormat === AssetType.jpeg + "_1200"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1200}
                      value={AssetType.png + "_1200"}
                      checked={selectedFormat === AssetType.png + "_1200"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1200}
                      value={AssetType.webp + "_1200"}
                      checked={selectedFormat === AssetType.webp + "_1200"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Ecom Resolution 1600px
                    <Typography className="text-[13px] text-gray-400">
                      8bit, sRGB, 72 DPi
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1600}
                      value={AssetType.jpeg + "_1600"}
                      checked={selectedFormat === AssetType.jpeg + "_1600"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1600}
                      value={AssetType.png + "_1600"}
                      checked={selectedFormat === AssetType.png + "_1600"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      disabled={resolution.width <= 1600}
                      value={AssetType.webp + "_1600"}
                      checked={selectedFormat === AssetType.webp + "_1600"}
                      onChange={handleChange}
                      name="radio-buttons"
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Custom Resolution sRGB file <br />
                    <Typography className="text-[13px] text-gray-400">
                      New size in px:
                    </Typography>
                    {isCustomResolution && (
                      <div className="flex">
                        <TextField
                          onChange={(e) =>
                            setCustomResolution({
                              width:
                                parseInt(e.target.value) > resolution.width
                                  ? resolution.width
                                  : parseInt(e.target.value),
                              height: customResolution?.height ?? 0,
                            })
                          }
                          value={customResolution?.width || resolution.width}
                          error={isError}
                          className="mt-10 w-100"
                          size="small"
                          type="number"
                        />
                        <span className="px-10 pt-15">x</span>
                        <TextField
                          onChange={(e) =>
                            setCustomResolution({
                              height:
                                parseInt(e.target.value) > resolution.height
                                  ? resolution.height
                                  : parseInt(e.target.value),
                              width: customResolution?.width ?? 0,
                            })
                          }
                          value={customResolution?.height || resolution.height}
                          error={isError}
                          className="mt-10 w-100"
                          size="small"
                          type="number"
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.jpeg + "_c"}
                      checked={selectedFormat === AssetType.jpeg + "_c"}
                      onChange={handleChangeCustomResolution}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.png + "_c"}
                      checked={selectedFormat === AssetType.png + "_c"}
                      onChange={handleChangeCustomResolution}
                      name="radio-buttons"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Radio
                      value={AssetType.webp + "_c"}
                      checked={selectedFormat === AssetType.webp + "_c"}
                      onChange={handleChangeCustomResolution}
                      name="radio-buttons"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDownloadFile}>Download</Button>
      </DialogActions>
    </Dialog>
  );
}
