import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import * as React from "react";
import { useContext } from "react";

import MediaTypeIcon from "../../../../components/media-format/media-type-icon";
import { AppContext } from "../../../../context/app-context";
import { useDirectus } from "../../../../providers/DirectusProvider";
import { MetaphoreCollections } from "../../../../types/collections";
import { useCollection } from "../../hooks/useCollection";
import ContextMenu from "../context-menu/context-menu";

type Props = {
  classes?: string;
  collection: MetaphoreCollections;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CollectionCard(props: Props): React.ReactElement {
  const { classes, collection, setLoading } = props;
  const { count, imageId, asset } = useCollection(collection);
  const { setCollectionId } = useContext(AppContext);
  const { directus } = useDirectus();

  const handleClickCollection = (): void => {
    setCollectionId(collection.id);
    setLoading(true);
  };

  return (
    <Card
      style={{ borderColor: "#E8E8E8", boxShadow: "none" }}
      className={`rounded-[15px] border-solid border-1 w-full relative w-[calc(33%_-_10px)] cursor-pointer ${classes}`}
    >
      <ContextMenu
        imageId="1"
        collection={collection}
        classes="absolute bg-white rounded-[25px] right-[17px] top-[17px]"
      />
      <div className="text-center bg-slate-100">
        <div
          className="cursor-pointer inline-block"
          onClick={handleClickCollection}
        >
          {asset ? (
            <MediaTypeIcon
              asset={asset}
              classes="text-[120px] max-h-[200px] object-contain inline-block"
            />
          ) : (
            <PhotoSizeSelectActualOutlinedIcon className="text-[150px] m-10 text-slate-400" />
          )}
        </div>
      </div>
      <CardContent
        onClick={handleClickCollection}
        className="p-[20px] leading-[17px] last:pb-[10px]"
      >
        <Typography className="text-[21px] cursor-pointer">
          {collection.name}
        </Typography>
        <Typography className="mb-[30px] text-[13px] text-slate-400">
          {count} Assets
        </Typography>
        <Typography className="font-light border-[#4a4a4a] text-[13px] text-slate-400">
          Last edited:{" "}
          {format(
            new Date(collection.date_updated || collection.date_created),
            "dd-MM-yyyy"
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}
