import { DefaultItem } from "@directus/sdk";
import { DescriptionOutlined, Favorite, FavoriteBorder } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Checkbox, Typography } from "@mui/material";
import { format } from "date-fns";
import { ReactElement, useContext } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

import Dropdawn from "../../../../components/dropdawn/dropdawn";
import MediaTypeIcon from "../../../../components/media-format/media-type-icon";
import { AppContext } from "../../../../context/app-context";
import { useDirectus } from "../../../../providers/DirectusProvider";
import Asset from "../../../../services/asset";
import { MetaphoreAssets } from "../../../../types/assets";
import { findRootCategoryById } from "../../../../utils";
import { ListLayout } from "../../../files/files";

type Props = {
    collectionAssets: DefaultItem<MetaphoreAssets>[] | undefined;
};

export default function CollectionItems(props: Props): ReactElement {
    const { collectionAssets } = props;
    const { category, selectedFiles, collectionId, setSelectedFiles } = useContext(AppContext);
    const { directus } = useDirectus();
    const navigate = useNavigate();
    const { setFavorite } = Asset(directus);
    const [cookies] = useCookies();

    const capitalizeFirstLetter = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, checked } = event.target;

        setSelectedFiles([...selectedFiles, id]);

        if (!checked) {
            setSelectedFiles(selectedFiles.filter(item => item !== id));
        }
    };

    const handleClickAsset = (id: string | number): void => {
        navigate(`/file/${id}/1`);
    }

    return (
        <>
            {!collectionAssets?.length && (
                <div className="w-full text-center">
                    Current collection doesn't have assets. <br/>
                    Please go to files 
                    <Link to="/files">
                        <DescriptionOutlined style={{ color: '#DD2127' }} className="mx-5" />
                    </Link> 
                    and upload assets to this collection.   
                </div>
            )}
            {cookies.listLayout === ListLayout.Grid ? 
                collectionAssets?.map(asset => (
                    <Card
                        key={asset.id}
                        id={asset.id} 
                        style={{borderColor: '#E8E8E8', boxShadow: 'none'}} 
                        className={`
                            shadow-none 
                            min-h-[350px]
                            rounded-[15px] 
                            flex flex-col 
                            border-solid 
                            border-1 w-full 
                            max-w-[calc(50%_-_15px)] 
                            HD:max-w-[calc(25%_-_15px)] 
                            HD+:max-w-[calc(20%_-_16px)] 
                            QHD:max-w-[calc(10%_-_18px)]
                        `}
                    >
                        <CardHeader
                            avatar={
                                <>
                                    <Checkbox 
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                        key={asset.id} 
                                        id={asset.id.toString()} 
                                        value={asset.id}  
                                        onChange={handleSelectFile} 
                                        checked={selectedFiles.includes(asset.id.toString())}
                                    />
                                    <Checkbox 
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFavorite(asset.id, event.target.checked)} 
                                        defaultChecked={asset.favorite} 
                                        icon={<FavoriteBorder />} 
                                        checkedIcon={<Favorite />} 
                                    />
                                </>
                            }
                            action={<Dropdawn asset={asset} setCollectionItem classes='mt-6' collectionId={collectionId ?? undefined} />}
                        />
                        <div onClick={() => handleClickAsset(asset.id)} className='mx-[20px] h-full flex items-center justify-center cursor-pointer'>
                            <MediaTypeIcon asset={asset} classes="text-[120px] max-h-[200px] object-contain" />
                        </div>
                        <CardContent className='p-[20px] leading-[17px] last:pb-[10px]'>
                            <Typography className='mb-[10px] text-[14px] break-keep'>
                                {asset.name}
                            </Typography>
                            <Typography className='font-light border-[#4a4a4a] text-[14px]'>
                                {asset.content_amount}{asset.unit} • {findRootCategoryById(asset?.category_id, category)}
                            </Typography>
                        </CardContent>
                    </Card>
                )) : !!collectionAssets?.length && (
                    <div className='w-full'>
                        <section>
                            <header className='flex mb-[25px] px-10'>
                                <div className='flex-1 pl-10' />
                                <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Name</div>
                                {/* <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Brand</div> */}
                                <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Category</div>
                                <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Assets type</div>
                                <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Last modified</div>
                            </header>
                            {collectionAssets?.map(asset => (
                                <div key={asset.id} style={{borderColor: '#E8E8E8'}} className='flex shadow-none rounded-[15px] border-solid border-1 w-full p-[10px] text-[14px] items-center mb-20'>
                                    <div className='flex-1'>
                                        <div className='flex items-center'>
                                            <div className='flex w-[40px] flex-col'>
                                                <Checkbox 
                                                    key={asset.id} 
                                                    id={asset.id.toString()} 
                                                    onChange={handleSelectFile} 
                                                    value={asset.id}  
                                                    checked={selectedFiles.includes(asset.id.toString())}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} 
                                                />
                                                <Checkbox 
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFavorite(asset.id, event.target.checked)}
                                                    defaultChecked={asset.favorite} 
                                                    icon={<FavoriteBorder />} 
                                                    checkedIcon={<Favorite />} 
                                                />
                                            </div>
                                            <div className="cursor-pointer" onClick={() => handleClickAsset(asset.id)}>
                                                <MediaTypeIcon asset={asset} classes="text-[50px] max-w-[50px] max-h-[50px] ml-20 object-contain" />
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => handleClickAsset(asset.id)} className='flex-1 pr-10 cursor-pointer'>{asset.name}</div>
                                    {/* <div className='flex-1'>{brand && (brand?.find(item => item.id === asset.brand_id))?.name}</div> */}
                                    <div onClick={() => handleClickAsset(asset.id)} className='flex-1 cursor-pointer'>{findRootCategoryById(asset?.category_id, category)}</div>
                                    <div onClick={() => handleClickAsset(asset.id)} className='flex-1 cursor-pointer'>{capitalizeFirstLetter(asset.asset_types.replace("_", " "))}</div>
                                    <div className='flex-1'>
                                        <div className='flex justify-between items-center'>
                                            {asset?.date_created && (format(new Date(asset?.date_updated || asset?.date_created), 'dd-MM-yyyy'))}
                                            <Dropdawn asset={asset} setCollectionItem collectionId={collectionId ?? undefined} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </section>
                    </div>
                )
            }
        </>
    );
}