import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { ReactElement, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import CardBox from "../../components/card/card-box";
import Filter from "../../components/filter/filter";
import LayoutSwitcher from "../../components/layout-switcher/layout-switcher";
import List from "../../components/list/list";
import ListItem from "../../components/list/list-item";
import Search from "../../components/search/search";
import TreeMenu from "../../components/tree-menu/tree-menu";
import UploadModal from "../../components/upload-modal/upload-modal";
import { AppContext } from "../../context/app-context";
import Navigation from "../../layout/navigation";
import { useDirectus } from "../../providers/DirectusProvider";

export enum ListLayout {
  Grid = "1",
  List = "2",
}

export default function Files(): ReactElement {
  const [totalAssets, setTotalAssets] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [draggedID, setDraggedID] = useState<number | undefined | string>();
  const { directus } = useDirectus();
  const [cookies, setCookie] = useCookies();
  const {
    category,
    countItems,
    assets,
    filter,
    sorting,
    setAssets,
    setCountItems,
    setFilter,
    setSorting,
  } = React.useContext(AppContext);

  const getFiles = async (limit?: number, offset?: number): Promise<void> => {
    await directus
      ?.items("metaphore_assets")
      .readByQuery({
        limit: limit,
        offset: offset,
        filter: {
          parent_id: {
            _null: true,
          },
          ...filter,
        },
        sort: sorting,
        meta: "*",
      })
      .then((response) => {
        if (response.data) {
          setAssets(response.data);
          setTotalAssets(response.meta?.filter_count || 0);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setFilter({});
  }, []);

  useEffect(() => {
    if (!cookies.listLayout) {
      setCookie("listLayout", 1);
    }

    getFiles(countItems);
  }, [filter, sorting, setFilter]);

  const handleChangePage = (event: SelectChangeEvent): void => {
    getFiles(parseInt(event.target.value));
    setCountItems(parseInt(event.target.value));
  };

  return (
    <>
      <Navigation>
        <Search label="Search in collections" />
        <FormControl className="w-[110px]">
          <InputLabel
            className="top-[16px]"
            id="demo-simple-select-autowidth-label"
          >
            Show
          </InputLabel>
          <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: 0 },
              "& legend span": { width: 0, padding: 0 },
              "& .MuiSelect-select": {
                paddingTop: "25px",
                paddingBottom: "8px",
              },
            }}
            className="shadow rounded-[15px] bg-white overflow-hidden h-full border-none"
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={countItems.toString()}
            onChange={handleChangePage}
            label="Show"
          >
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={32}>32</MenuItem>
            <MenuItem value={64}>64</MenuItem>
            <MenuItem value={128}>128</MenuItem>
          </Select>
        </FormControl>
        <LayoutSwitcher />
        <UploadModal />
      </Navigation>
      <div className="flex w-full flex-row gap-[25px] items-start">
        <div className="basis-[270px] shrink-0">
          <TreeMenu name="Categories" collection={category} />
        </div>
        <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex">
          <Filter />
          {loading ? (
            <Box className="flex w-full justify-center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {cookies.listLayout === ListLayout.Grid ? (
                assets?.map((asset, index) => (
                  <CardBox
                    getDradId={draggedID}
                    setDragId={setDraggedID}
                    isCheckbox
                    draggable={true}
                    key={asset.id}
                    asset={asset}
                    offset={index + 1}
                  />
                ))
              ) : (
                <List>
                  {assets?.map((asset, index) => (
                    <ListItem
                      getDradId={draggedID}
                      setDragId={setDraggedID}
                      isCheckbox
                      draggable={true}
                      key={asset.id}
                      asset={asset}
                      offset={index + 1}
                    />
                  ))}
                </List>
              )}
              {!!Math.round(totalAssets / countItems) && (
                <Pagination
                  sx={{
                    "& .MuiButtonBase-root.Mui-selected": {
                      backgroundColor: "#4A4A4A",
                      color: "white",
                    },
                  }}
                  onChange={(_, page: number) => {
                    getFiles(countItems, countItems * page - countItems);
                  }}
                  className="w-full flex justify-center"
                  count={Math.ceil(totalAssets / countItems)}
                  size="large"
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
