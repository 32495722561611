import { DefaultItem } from '@directus/sdk';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';

import { useDirectus } from '../../providers/DirectusProvider';

export interface DialogProps {
  setToggle: (value: boolean) => void;
  isOpen: boolean;
  collectionId: string;
}

export default function ShareLink(props: DialogProps): React.ReactElement {
  const { setToggle, isOpen, collectionId } = props;
  const { directus } = useDirectus();

  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const [expirationDate, setExpirationDate] = React.useState<Dayjs | null>(null);
  const [user, setUser] = React.useState<any>();
  const [urls, setUrls] = React.useState<DefaultItem<unknown>[] | undefined | null>();

  React.useEffect(() => {
    (async (): Promise<void> => {
      const user = await directus?.users.me.read();

      const links = await directus?.items('metaphore_external_links').readByQuery({
        filter: {
          user_created: {
            _eq: user?.id
          },
          collection_id: {
            _eq: collectionId
          }
        }
      });

      links?.data?.map((url) => {
        setStartDate(dayjs(url.start_date));
        setExpirationDate(dayjs(url.end_date));
      });

      setUrls(links?.data);
      setUser(user);
    })()
  }, [isOpen]);

  const handleCreateLink = async (): Promise<void> => {
    if (user) {
      await directus?.items('metaphore_external_links').createOne({
        start_date: startDate?.toDate(),
        end_date: expirationDate?.toDate(),
        collection_id: collectionId,
      });
    }

    setToggle(false);
  }

  const handleRemoveLink = async (id: string): Promise<void> => {
    if (confirm("Are you sure?")) {
      if (user) {
        await directus?.items('metaphore_external_links').deleteOne(id);
      }
  
      setStartDate(dayjs(dayjs(new Date())));
      setExpirationDate(null);
      setToggle(false);
    }
  }

  const handleClose = (): void => {
    setToggle(false);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Create external link
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {urls?.length ? urls.map((url: any) => (
            <div key={url.id} className='flex text-[#4a4a4a] justify-between items-center 
            
            '>
              <div
                title={`${process.env.REACT_APP_EXTERNAL_USER_LINK}/${url.id}`} 
                className='text-[13px] font-semibold text-ellipsis overflow-hidden whitespace-nowrap'
              >
                {`Public link: ${process.env.REACT_APP_EXTERNAL_USER_LINK}/${url.id}`}
              </div>
              <div className='pl-10 whitespace-nowrap'>
                <Button 
                  className='rounded-[20px] !text-[#4a4a4a] !border-[#4a4a4a] whitespace-nowrap mr-[10px]' 
                  variant="outlined" 
                  size="small" 
                  startIcon={<LinkIcon />}
                  onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_EXTERNAL_USER_LINK}/${url.id}` ?? '')}}
                >
                  Copy link
                </Button>
                <IconButton
                  onClick={() => handleRemoveLink(url.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) : (
            <div>
              <div className='text-[14px] font-semibold pb-5'>Set expiration date:</div>
              <div className='flex justify-between'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker 
                      disablePast 
                      label="Start date" 
                      defaultValue={startDate} 
                      onChange={(value) => setStartDate(dayjs(value))}
                    />
                    <DatePicker
                      disablePast
                      label="Expiration date"
                      value={expirationDate}
                      onChange={(value) => setExpirationDate(dayjs(value))}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button 
            onClick={handleClose}
        >
          Close
        </Button>
        {!urls?.length && (
          <Button 
              onClick={handleCreateLink}
          >
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}