import { MetaphoreCategories } from "../types/categories";

export const findRootCategoryById = (id: number | undefined, categories: MetaphoreCategories[]): string => {
    const category: MetaphoreCategories | undefined = categories.find(item => item.id === id);

    if (category?.parent_id) {
        return findRootCategoryById(category.parent_id, categories);    
    } 

    return category?.name || '';
};