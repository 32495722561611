import { DefaultItem } from "@directus/sdk";

import { useDirectus } from "../providers/DirectusProvider";
import { MetaphoreAssets } from "../types/assets";
import { MetaphoreCollections } from "../types/collections";

interface Collections {
    getCollections: (sort?: any[], filter?: any) => Promise<MetaphoreCollections[] | undefined | null>;
    getSharedCollections: (sort?: any[], filter?: any) => Promise<MetaphoreCollections[] | undefined | null>;
    getCollectionById: (id: number | string) => Promise<MetaphoreCollections | undefined | null>;
    getCollectionItemsById: (id: number | string, filter?: object, sort?: any[]) => Promise<DefaultItem<MetaphoreAssets>[] | undefined | null>;
    getCollectionsByName: (name: string) => Promise<MetaphoreCollections[] | undefined>;
    deleteCollectionById: (id: number | string) => Promise<void>;
    renameCollectionById: (id: number | string, name: string) => Promise<void>;
    createCollection: (name: string, userId: string | number) => Promise<void>;
    setShareCollection: (id: number | string, users: any[]) => Promise<void>;
}

const Collection = (): Collections => {
    const { directus } = useDirectus();
    const COLLECTION_TABLE_NAME = 'metaphore_collections';
    const ASSETS_TABLE_NAME = 'metaphore_assets';

    const getCollections = async (sort?: any[], filter?: any): Promise<MetaphoreCollections[] | undefined | null> => {
        try {
            const user = await directus?.users.me.read();
            const response = await directus?.items(COLLECTION_TABLE_NAME).readByQuery({
                filter: {
                    ...filter,
                    user_id: user?.id
                },
                sort: sort,
                meta: "*",
            });
            
            return response?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getSharedCollections = async (sort?: any[], filter?: any): Promise<MetaphoreCollections[] | undefined | null> => {
        try {
            const user = await directus?.users.me.read();
            const response = await directus?.items(COLLECTION_TABLE_NAME).readByQuery({
                filter: {
                    ...filter,
                    shared_user_ids: { directus_users_id: user?.id }
                },
                sort: sort,
                meta: "*",
            });
            
            return response?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const setShareCollection = async (id: number | string, users: any[]): Promise<void> => {
        // eslint-disable-next-line no-console
        // console.log(id, users)
        try {
            await directus?.items(COLLECTION_TABLE_NAME).updateOne(id, {
                shared_user_ids: users
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const createCollection = async (name: string, userId: string | number): Promise<void> => {
        try {
            await directus?.items(COLLECTION_TABLE_NAME).createOne({
                name: name,
                user_id: userId
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const deleteCollectionById = async (id: number | string): Promise<void> => {
        try {
            await directus?.items(COLLECTION_TABLE_NAME).deleteOne(id);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getCollectionById = async (id: number | string): Promise<MetaphoreCollections | undefined | null> => {
        try {
            const response = await directus?.items(COLLECTION_TABLE_NAME).readOne(id);
            
            return response;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getCollectionsByName = async (name: string): Promise<MetaphoreCollections[] | undefined> => {
        try {
            const response = await directus?.items(COLLECTION_TABLE_NAME).readByQuery({
                filter: {
                    name: {
                        _icontains: `%${name}%`
                    }
                }
            });

            return response?.data ?? undefined;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const renameCollectionById = async (id: number | string, name: string): Promise<void> => {
        try {
            await directus?.items(COLLECTION_TABLE_NAME).updateOne(id, {
                name: name,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getCollectionItemsById = async (id: number | string, filter?: object, sort?: any[]): Promise<DefaultItem<MetaphoreAssets>[] | undefined | null> => {
        try {
            const response = await directus?.items(ASSETS_TABLE_NAME).readByQuery({
                filter: {
                    collection_id: {
                        metaphore_collections_id: {
                            _eq: id
                        }
                    },
                    ...filter
                },
                sort: sort
            });
            
            return response?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    return {
        getCollections,
        getSharedCollections,
        getCollectionById,
        getCollectionItemsById,
        getCollectionsByName,
        deleteCollectionById,
        renameCollectionById,
        createCollection,
        setShareCollection,
    };
};

export default Collection;
