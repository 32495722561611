import {
    AddTaskOutlined, ChevronLeftOutlined, ChevronRightOutlined, DescriptionOutlined,
    FolderOutlined,
    HomeOutlined
} from '@mui/icons-material';
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { ReactElement, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppContext } from '../context/app-context';

export default function MainSidebar(): ReactElement {
    const [isOpen, setOpen] = useState<boolean>(false);
    const currentLocation = window.location.pathname;
    const [searchParams] = useSearchParams();
    const { role, user } = useContext(AppContext);
    const navigate = useNavigate();

    const routeListItems = [
        {
            label: 'Home',
            icon: <HomeOutlined className='text-[22px]' />,
            route: '/',
        },
        {
            label: 'Files', 
            icon: <DescriptionOutlined className='text-[22px]' />,
            route: '/files',
        },
        {
            label: 'Collections',
            icon: <FolderOutlined className='text-[22px]' />,
            route: '/collections',
        }
    ];
    
    // Temporary exclude #29
    // if (role && role !== Roles.regular) {
    //     routeListItems.push({
    //         label: 'Approvals',
    //         icon: <AddTaskOutlined className='text-[22px]' />,
    //         route: '/approvals',
    //     });
    // }

    function onClick(route: string): void {
        navigate(`${route}?${searchParams}`);
    }

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            open={isOpen}
            PaperProps={{ className: `fixed transition-all overflow-hidden shadow border ${isOpen ? 'w-[120px]' : 'w-[50px]'}` }}
            className={`sticky z-50 overflow-hidden transition-all ${isOpen ? 'w-[120px]' : 'w-[50px]'}`}
        >
            <List>
                <ListItem disablePadding className='border-b'>
                    <ListItemButton onClick={() => setOpen(!isOpen)} className='min-h-[75px] justify-center'>
                        {isOpen ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
                    </ListItemButton>
                </ListItem>
                {routeListItems.map((element) => (
                    <ListItem key={element.route} disablePadding className='border-b justify-center'>
                        <ListItemButton
                            onClick={() => onClick(element.route)}
                            className={`relative min-h-[75px] flex-col items-center justify-center transition-all ${isOpen ? 'w-100' : 'w-0'}`}
                        >
                            <ListItemIcon className={`justify-center ${currentLocation === process.env.PUBLIC_URL + element.route ? 'fill-company [&>svg]:fill-company' : 'fill-companyDarkGrey [&>svg]:fill-companyDarkGrey'}`}>
                                {element.icon}
                            </ListItemIcon>
                            <ListItemText className={`flex-none [&>span]:text-[14px] overflow-hidden text-center text-lg transition-all ${isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0'} ${currentLocation === process.env.PUBLIC_URL + element.route ? 'text-company' : 'text-companyDarkGrey'} pl-3`}>
                                {element.label}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem> 
                ))}
            </List>
        </Drawer>
    );
}
