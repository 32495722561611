import { DefaultItem, ItemInput, UserItem } from "@directus/sdk";
import { createContext } from "react";

import { Approvals } from "../types/approvals";
import { MetaphoreAssets } from "../types/assets";
import { MetaphoreCategories } from "../types/categories";
import { MetaphoreCollections } from "../types/collections";

type Props = {
    sessions: Approvals[] | undefined,
    token: string | undefined | null,
    lastAccess: string | undefined | null,
    role: string | undefined,
    user: ItemInput<UserItem<unknown>> | undefined,
    category: MetaphoreCategories[],
    assets: DefaultItem<MetaphoreAssets>[] | undefined | null,
    selectedFiles: string[],
    selectedFilesAll: boolean,
    filter: object,
    sorting: any[],
    filterCollections: MetaphoreCollections[] | undefined,
    collections: MetaphoreCollections[] | undefined,
    sharedCollections: MetaphoreCollections[] | undefined,
    collectionId: string | number | undefined,
    countItems: number,
    setSessions: React.Dispatch<React.SetStateAction<Approvals[] | undefined>>,
    setToken: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setRole: React.Dispatch<React.SetStateAction<string | undefined>>,
    setUser: React.Dispatch<React.SetStateAction<ItemInput<UserItem<unknown>> | undefined>>,
    setCategory: React.Dispatch<React.SetStateAction<MetaphoreCategories[]>>,
    setAssets: React.Dispatch<React.SetStateAction<DefaultItem<MetaphoreAssets>[] | undefined | null>>,
    setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>,
    setSelectedFilesAll: React.Dispatch<React.SetStateAction<boolean>>,
    setFilter: React.Dispatch<React.SetStateAction<object>>,
    setSorting: React.Dispatch<React.SetStateAction<any[]>>,
    setFilterCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>,
    setCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>,
    setSharedCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>,
    setCollectionId: React.Dispatch<React.SetStateAction<string | number | undefined>>,
    setCountItems: React.Dispatch<React.SetStateAction<number>>,
}

export const AppContext = createContext<Props>({
    sessions: undefined,
    role: undefined,
    user: undefined,
    lastAccess: undefined,
    token: undefined,
    category: [],
    assets: [],
    selectedFiles: [],
    selectedFilesAll: false,
    filter: {},
    sorting: [],
    filterCollections: [],
    collections: [],
    sharedCollections: [],
    collectionId: undefined,
    countItems: 16,
    setSessions: () => undefined,
    setToken: () => undefined,
    setRole: () => undefined,
    setUser: () => undefined,
    setCategory: () => undefined,
    setAssets: () => undefined,
    setSelectedFiles: () => undefined,
    setSelectedFilesAll: () => undefined,
    setFilter: () => undefined,
    setSorting: () => undefined,
    setFilterCollections: () => undefined,
    setCollections: () => undefined,
    setSharedCollections: () => undefined,
    setCollectionId: () => undefined,
    setCountItems: () => undefined,
})
