import { DefaultItem, ItemInput, UserItem } from "@directus/sdk";
import { useEffect, useState } from "react";

import { useDirectus } from "../providers/DirectusProvider";
import Collection from "../services/collection";
import User from "../services/user";
import { Approvals } from "../types/approvals";
import { MetaphoreAssets } from "../types/assets";
import { MetaphoreCategories } from "../types/categories";
import { MetaphoreCollections } from "../types/collections";

interface Props {
    sessions: Approvals[] | undefined,
    token: string | undefined | null;
    role: string | undefined;
    user: ItemInput<UserItem<unknown>> | undefined;
    category: MetaphoreCategories[];
    lastAccess: string | undefined | null;
    assets: DefaultItem<MetaphoreAssets>[] | undefined | null;
    selectedFiles: string[];
    selectedFilesAll: boolean;
    filter: object;
    sorting: string[];
    filterCollections: MetaphoreCollections[] | undefined;
    collections: MetaphoreCollections[] | undefined;
    sharedCollections: MetaphoreCollections[] | undefined;
    collectionId: string | number | undefined;
    countItems: number;
    setSessions: React.Dispatch<React.SetStateAction<Approvals[] | undefined>>;
    setToken: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
    setUser: React.Dispatch<React.SetStateAction<ItemInput<UserItem<unknown>> | undefined>>;
    setCategory: React.Dispatch<React.SetStateAction<MetaphoreCategories[]>>;
    setAssets: React.Dispatch<React.SetStateAction<DefaultItem<MetaphoreAssets>[] | undefined | null>>;
    setLastAccess?: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFilesAll: React.Dispatch<React.SetStateAction<boolean>>;
    setFilter: React.Dispatch<React.SetStateAction<object>>;
    setSorting: React.Dispatch<React.SetStateAction<string[]>>;
    setFilterCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>;
    setCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>;
    setSharedCollections: React.Dispatch<React.SetStateAction<MetaphoreCollections[] | undefined>>;
    setCollectionId: React.Dispatch<React.SetStateAction<string | number | undefined>>;
    setCountItems: React.Dispatch<React.SetStateAction<number>>;
}

export const useApp = (): Props => {
    const [collections, setCollections] = useState<MetaphoreCollections[] | undefined>();
    const [countItems, setCountItems] = useState<number>(16);
    const [sessions, setSessions] = useState<Approvals[] | undefined>();
    const [sharedCollections, setSharedCollections] = useState<MetaphoreCollections[] | undefined>();
    const [filterCollections, setFilterCollections] = useState<MetaphoreCollections[] | undefined>();
    const [collectionId, setCollectionId] = useState<string | number | undefined>();
    const [token, setToken] = useState<string | undefined | null>();
    const [assets, setAssets] = useState<DefaultItem<MetaphoreAssets>[] | undefined | null>([]);
    const [role, setRole] = useState<string | undefined>();
    const [user, setUser] = useState<ItemInput<UserItem<unknown>>>();
    const [category, setCategory] = useState<MetaphoreCategories[]>([]);
    const [lastAccess, setLastAccess] = useState<string | undefined | null>();
    const [selectedFilesAll, setSelectedFilesAll] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [filter, setFilter] = useState<object>({});
    const [sorting, setSorting] = useState<string[]>(["-date_created"]);
    const { getCollections, getSharedCollections } = Collection();
    const { directus } = useDirectus();
    
    useEffect(() => {
        const getToken = async (): Promise<void> => {
            const authToken = await directus?.auth.token;

            setToken(authToken);

            if (authToken) {
                const category = await directus?.items('metaphore_categories').readByQuery({limit: -1, sort: ['order_index', "name"]});
                const user = await directus?.users.me.read();
                
                setLastAccess(user?.last_access);
                setRole(await User(directus).getUserRole());
                setUser(user); 

                if(category?.data) {
                    setCategory(category.data);
                }

                setCollections(await getCollections(["name"]) ?? undefined);
                setSharedCollections(await getSharedCollections(["name"]) ?? undefined);
            }
        };

        if (!token) {
            getToken();
        }
    }, []);

    return {
        sessions,
        token,
        role,
        user,
        category,
        lastAccess,
        assets,
        selectedFiles,
        selectedFilesAll,
        filter,
        sorting,
        filterCollections,
        collections,
        sharedCollections,
        collectionId,
        countItems,
        setSessions,
        setToken,
        setRole,
        setUser,
        setCategory,
        setAssets,
        setSelectedFiles,
        setSelectedFilesAll,
        setFilter,
        setSorting,
        setFilterCollections,
        setCollections,
        setSharedCollections,
        setCollectionId,
        setCountItems
    };
}