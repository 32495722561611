import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import { AppContext } from "../../../context/app-context";
import { MetaphoreComments } from "../../../types/comment";

import { EditComment } from "./edit-comment";

interface SubCommentsProps {
  comments: MetaphoreComments[];
  commentId: string | number;
  editCommentId: string | number | undefined;

  handleEditComment: (id: string | number, commet: string) => void;
  setEditCommentId: (id: string | number) => void;
  removeComment: (id: string | number) => void;
}

export function SubComments(props: SubCommentsProps): React.ReactElement {
  const {
    comments,
    commentId,
    editCommentId,
    removeComment,
    setEditCommentId,
    handleEditComment,
  } = props;

  const { user } = React.useContext(AppContext);

  const parentComments = comments.filter((c) => c.parent_id == commentId);

  return (
    <div>
      {parentComments.length > 0 &&
        parentComments.map((comment) => (
          <div
            key={comment.id}
            className="p-10 bg-slate-100 ml-20 mb-5 rounded-10"
          >
            <div className="flex align-middle justify-between mb-5">
              {comment.email}
              <div className="text-[11px]">
                {format(new Date(comment.date_created), "dd/MM/yyyy HH:mm")}
                {user?.id === comment.user_created && (
                  <>
                    <IconButton
                      onClick={() => setEditCommentId(comment.id)}
                      className="ml-5"
                      color="error"
                      size="small"
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      onClick={() => removeComment(comment.id)}
                      className="ml-5"
                      color="error"
                      size="small"
                    >
                      <DeleteOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <div className="text-[14px]">
              {(comment.fname || comment.lname) && (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {comment.fname} {comment.lname} -
                </Typography>
              )}
              <span className="text-slate-500">{` ${comment.comment}`}</span>
            </div>
            <EditComment
              comment={comment}
              editCommentId={editCommentId}
              handleEditComment={handleEditComment}
            />
          </div>
        ))}
    </div>
  );
}
