import DoneIcon from "@mui/icons-material/Done";
import { IconButton, TextField } from "@mui/material";
import React from "react";

import { AppContext } from "../../../context/app-context";
import { MetaphoreComments } from "../../../types/comment";

interface EditCommentProps {
    comment: MetaphoreComments;
    editCommentId: string | number | undefined;
  
    handleEditComment: (id: string | number, commet: string) => void;
  }

export function EditComment(props: EditCommentProps): React.ReactElement {
    const { comment, editCommentId, handleEditComment } = props;
  
    const [editComment, setEditComment] = React.useState<string>();
    const editableCommentRef = React.useRef<HTMLInputElement>();
  
    const { user } = React.useContext(AppContext);
  
    if (!editCommentId) {
      return <></>;
    }
  
    return (
      <>
        {user?.id === comment.user_created && comment.id === editCommentId && (
          <div className="flex my-5">
            <TextField
              onChange={(e) => setEditComment(e.target.value)}
              inputRef={editableCommentRef}
              size="small"
              focused
              autoFocus
              label="Edit comment"
              value={editComment ?? comment.comment}
              className="w-full mr-5"
            />
            <IconButton
              onClick={() =>
                handleEditComment(
                  comment.id,
                  editableCommentRef.current?.value || ""
                )
              }
              className="ml-5"
              color="success"
              size="medium"
            >
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </>
    );
  }