import { Alert, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import MetaphoreLogo from '../../assets/media/metaphore-logo.png';
import { useApp } from '../../hooks/useApp';
import { useDirectus } from '../../providers/DirectusProvider';
import Login from '../login/login';

export default function ResetPassword(): React.ReactElement {
    const [error, setError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string>();
    const [confirmPassword, setConfirmPassword] = React.useState<string>();
    const { setToken } = useApp();
    const [queryParameters] = useSearchParams();
    const { directus } = useDirectus();
    
    const handleResetPassword = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        
        const token = queryParameters.get('token');

        if (token?.length && password && (password === confirmPassword)) {
            await directus?.auth.password.reset(token, password);

            setSuccess(true);
            setError(false);
        } else {
            setError(true);
            setSuccess(false);
        }
    }

    if (!queryParameters.get('token')) {
        return (<Login setToken={setToken} />);
    }

    return (
        <div style={{ minHeight: 'inherit', background: '#f6f6f6' }}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img className='max-w-full mb-[120px] mt-[80px] mb-[40px] max-w-[300px]' src={MetaphoreLogo} alt='Company Logo' />
                    <Box 
                        sx={{ boxShadow: '0px 10px 15px #ccc', borderColor: '#dc2227', padding: '20px 40px 30px 40px' }}
                        className='bg-white border-t-4 w-[500px]' 
                        component="form" 
                        noValidate 
                        onSubmit={handleResetPassword}
                    >
                        <Typography 
                            className="mb-[35px] mt-10 font-['Open_Sans']"
                            component="h1" 
                            variant="h5"
                        >
                            Reset your password
                        </Typography>
                        {error && (<Alert className='w-full mb-20' severity="error">Password is not same.</Alert>)}
                        {success && (
                            <>
                                <Alert className='w-full mb-20'>Password was changed.</Alert>
                                <Link className='text-[14px] block text-center h-[30px] w-full text-[#DC2227] mt-20 mb-0 p-[6px] bg-[#DC2227] hover:bg-[#DC2227] text-white rounded-none capitalize' to="/">
                                    Login
                                </Link>
                            </>
                        )}
                        {!success && (
                            <>
                                <InputLabel className='text-[12px]'>Password</InputLabel>
                                <TextField
                                    className='m-0 mb-[15px] bg-[#f6f6f6]'
                                    required
                                    fullWidth
                                    autoFocus
                                    type="password"
                                    variant="standard"
                                    sx={{ '& .MuiInputBase-input': { padding: '7px 5px' } }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                />
                                <InputLabel className='text-[12px]'>Confirm password</InputLabel>
                                <TextField
                                    className='m-0 mb-10 bg-[#f6f6f6]'
                                    required
                                    fullWidth
                                    type="password"
                                    variant="standard"
                                    sx={{ '& .MuiInputBase-input': { padding: '7px 5px' } }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                                />
                                <Button
                                    className="mt-20 mb-0 p-2 bg-[#DC2227] hover:bg-[#DC2227] text-white rounded-none capitalize"
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleResetPassword}
                                >
                                    Reset password
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </div>
    );
}