import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Approvals } from '../../../../types/approvals';

type Props = {
    name: string;
    sessions: Approvals[] | null | undefined;
};

export default function LeftNavigation(props: Props): React.ReactElement {
    const { name, sessions } = props;
    
    const state = useLocation().state;

    if (!sessions?.length) {
        return <></>;
    }

    return (
        <List
            sx={{ bgcolor: 'background.paper' }}
            className="shadow max-w-[320px] width-full rounded-[15px] overflow-hidden pt-[8px] mb-[25px] ml-[45px]"
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {name}
                </ListSubheader>
            }
        >
            <Accordion className='accordion'>
                <AccordionSummary
                    className='m-0'
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <ListItemText primary={`Upload Sessions for approval (${sessions?.length})`} />
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    <List component="div" disablePadding>
                        {sessions && sessions.map((session) => {
                            return (
                                <Link 
                                    key={session.id} 
                                    to={`/approval/${session.id}`} 
                                    state={{ id: session.id }}
                                >
                                    <ListItemButton 
                                        selected={session.id === state?.id} 
                                        className='border-t-1 border-solid border-slate-300 pl-[30px]' 
                                        sx={{ pl: 6 }}
                                    >
                                        <ListItemText primary={session.email} />
                                    </ListItemButton>
                                </Link>
                            );
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
        </List>
    );
}

