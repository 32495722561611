import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import { Action } from '../../../../types/enums/action';
import { Status } from '../../../../types/enums/status';


type Props = {
  id: string;
  status: string;
  approval: string;
  actions: {
    approve: (id: string, action: string) => void,
    reject: (id: string, action: string) => void,
    download: (id: string) => void,
  }
};

export default function ContextMenu(props: Props): React.ReactElement {
  const { id, actions, status, approval } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
      setAnchorEl(null);
  };

  return (
    <>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon style={{color: open ? '#DD2127' : ''}} />
        </IconButton>
        <Menu
            id="long-menu"
            className='shadow rounded-[15px]'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: 'auto',
                padding: 0,
                borderRadius: '15px'
              },
            }}
        >
            <MenuItem 
                disabled={approval === Status.Review} 
                sx={{ '&:hover': { color: '#DD2127' } }} 
                onClick={() => {
                    actions.approve(id, Action.Review);
                    handleClose();
                }}
            >
                Set to "Not reviewed"
            </MenuItem>
            <MenuItem 
                disabled={approval === Status.Approved} 
                sx={{ '&:hover': { color: '#DD2127' } }} 
                onClick={() => {
                    actions.approve(id, Action.Approve);
                    handleClose();
                }}
            >
                Set to "Approved"
            </MenuItem>
            <MenuItem 
                disabled={approval === Status.Rejected} 
                sx={{ '&:hover': { color: '#DD2127' } }} 
                onClick={() => {
                    actions.approve(id, Action.Reject);
                    handleClose();
                }}
            >
                Set to "Rejected"
            </MenuItem>
            <MenuItem 
                disabled={status === Status.Published || approval !== Status.Approved} 
                sx={{ '&:hover': { color: '#DD2127' } }} 
                onClick={() => {
                    actions.approve(id, Action.Publish);
                    handleClose();
                }}
            >
                Publish Approved
            </MenuItem>
            <MenuItem 
                disabled={status === Status.Archived || approval !== Status.Approved} 
                sx={{ '&:hover': { color: '#DD2127' } }} 
                onClick={() => {
                    actions.approve(id, Action.Archive);
                    handleClose();
                }}
            >
                Archive
            </MenuItem>
        </Menu>
    </>
  );
}