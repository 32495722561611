import { Chip } from '@mui/material';
import { ReactElement } from 'react';

import Title from './title';

type Props = {
    tags: string[];
};

export default function Tags(props: Props): ReactElement {
    const { tags } = props;
    
    return (
        <div className="text-16 mb-[40px]">
            <Title>Tags</Title>
            {tags.map((tag, index) => (
                <Chip 
                    className='mr-5 mb-5' 
                    key={index} 
                    label={tag} 
                    size="small" 
                    variant="outlined" 
                />
            ))}
        </div>
    );
}
