import { ReactElement } from 'react';

import { MetaphoreCollections } from '../../../../types/collections';
import ContextMenu from '../context-menu/context-menu';

type Props = {
    title: string;
    amount: number;
    collection: MetaphoreCollections | undefined;
};

export default function Header(props: Props): ReactElement {
    const { title, amount, collection } = props;
    
    return(
        <div className="flex w-full justify-items-center h-fit">
            <h2 className="text-[24px] text-rose-600">
                {title} 
                <span className="text-[16px] text-slate-400"> ({amount})</span>
            </h2>
            {collection && (
                <ContextMenu 
                    imageId='1' 
                    classes="bg-white rounded-[25px] right-[17px] top-[17px]"
                    collection={collection}  
                />
            )}
        </div>
    );
};
