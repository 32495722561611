import { Button, DialogActions, Divider, List, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { AppContext } from "../../context/app-context";
import { useComments } from "../../hooks/useComments";

import { EditComment } from "./parts/edit-comment";
import { ListComments } from "./parts/list-comments";
import { ReplayComment } from "./parts/reply-comment";
import { SubComments } from "./parts/sub-comments";

interface CommentsProps {
  isOpen: boolean;
  assetId: string;

  closeDialog: () => void;
}

export default function Comments(props: CommentsProps): React.ReactElement {
  const { isOpen, assetId, closeDialog } = props;

  const { user } = React.useContext(AppContext);
  const {
    commentText,
    comments,
    editCommentId,
    refresh,
    setCommentText,
    setEditCommentId,
    setRefresh,
    removeComment,
    handleRemoveComment,
    handleEditComment,
    handleAddComment,
  } = useComments(assetId);

  const handleClose = (): void => {
    closeDialog();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Comments
        <span>{` ${comments?.length || ""}`}</span>
      </DialogTitle>
      <DialogContent dividers>
        <List className="pt-0 min-w-[550px] p-0 mb-[-1px]">
          {/* {loading && (
            <Box className="text-center">
              <CircularProgress />
            </Box>
          )} */}
          {comments &&
            comments.map(
              (comment, index, array) =>
                !comment.parent_id && (
                  <div key={comment.date_created}>
                    <ListComments
                      comment={comment}
                      setEditCommentId={setEditCommentId}
                      user={user}
                      handleRemoveComment={handleRemoveComment}
                    />
                    <EditComment
                      comment={comment}
                      editCommentId={editCommentId}
                      handleEditComment={handleEditComment}
                    />
                    <SubComments
                      removeComment={removeComment}
                      commentId={comment.id}
                      comments={array}
                      editCommentId={editCommentId}
                      handleEditComment={handleEditComment}
                      setEditCommentId={setEditCommentId}
                    />
                    <ReplayComment
                      comment={comment}
                      refresh={() => setRefresh(!refresh)}
                    />
                    <Divider className="mx-5" />
                  </div>
                )
            )}
        </List>
        <TextField
          onChange={(e) => setCommentText(e.target.value)}
          label="Your comment"
          placeholder="Type something here…"
          className="w-full"
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!commentText}
          onClick={() => {
            handleAddComment();
            closeDialog();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
