import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { IconButton } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { format } from 'date-fns';
import * as React from 'react';

import { AppContext } from '../../../../context/app-context';
import { getDirectusAssetsPath, useDirectus } from '../../../../providers/DirectusProvider';
import { MetaphoreCollections } from '../../../../types/collections';
import { useCollection } from '../../hooks/useCollection';
import ContextMenu from '../context-menu/context-menu';

type Props = {
    classes?: string;
    collection: MetaphoreCollections;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CollectionListItem(props: Props): React.ReactElement {
    const { collection, setLoading } = props;
    const { count, imageId } = useCollection(collection);
    const { setCollectionId } = React.useContext(AppContext);
    const { directus } = useDirectus();

    const handleClickCollection = (): void => {
        setCollectionId(collection.id);
        setLoading(true);
    }

    return (
        <div style={{borderColor: '#E8E8E8'}} className='flex shadow-none rounded-[15px] mb-20 border-solid border-1 w-full p-[10px] text-[14px] items-center border-slate-400'>
            <div 
                className='flex-1 cursor-pointer'
                onClick={handleClickCollection}
            >
                {imageId ? (
                    <CardMedia 
                        className='max-w-[50px] max-h-[50px] ml-20 object-contain'
                        component="img" 
                        image={`${getDirectusAssetsPath(directus)}/${imageId}?height=80&quality=80`} 
                        alt={collection.name} 
                    />
                ) : <PhotoSizeSelectActualOutlinedIcon className='text-[50px] m-10 text-slate-400' />}
            </div>
            <div onClick={handleClickCollection} className='flex-1 cursor-pointer'>{collection.name}</div>
            <div onClick={handleClickCollection} className='flex-1 cursor-pointer'>{count}</div>
            <div onClick={handleClickCollection} className='flex-1 cursor-pointer'>{format(new Date(collection.date_updated || collection.date_created), 'dd-MM-yyyy')}</div>
            <div className='flex-1'>
                <div className='flex justify-end'>
                    <IconButton>
                        <GroupOutlinedIcon />
                    </IconButton>
                    <ContextMenu imageId='1' collection={collection} classes="bg-white rounded-[25px]" />
                </div>
            </div>
        </div>
    );
}