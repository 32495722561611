import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { getDirectusAssetsPath, useDirectus } from '../../../providers/DirectusProvider';
import useDetailPage from '../hooks/useDetailPage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer(): ReactElement {
    const { asset, pageNumber, numPages, onDocumentLoadSuccess, setPageNumber } = useDetailPage();
    const { directus } = useDirectus();

    return (
        <div className="relative">
            <Document
                className="max-w-[100%]"
                file={`${getDirectusAssetsPath(directus)}/${asset?.image}`}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    className="max-w-[100%] !min-w-full flex justify-center"
                    canvasBackground="transparent"
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    height={800}
                    pageNumber={pageNumber}
                />
            </Document>
            {!!numPages && (
                <div className="flex absolute justify-center w-full bottom-0 items-center">
                    <IconButton
                        onClick={() => setPageNumber((prev: number) => prev <= 1 ? prev : --prev)}
                        size="large"
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                    <span className="px-10">{pageNumber}</span>
                    <IconButton
                        onClick={() => setPageNumber((prev: number) => prev >= numPages ? prev : ++prev)}
                        size="large"
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
