import { ReactNode, useContext } from "react";

import { AppContext } from "../../context/app-context";

type Props = {
  children?: ReactNode;
};

const alowwedUserRoles: string[] = ["Super", "Admin"];

const UserRole = (props: Props): any => {
  const { children } = props;
  const { role } = useContext(AppContext);

  if (!role) {
    return;
  }

  return alowwedUserRoles.includes(role) ? children : undefined;
};

export default UserRole;
