import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { option } from "yargs";

import { Options } from "../upload-modal";

type Props = {
  label: string;
  name: string;
  list?: any[] | undefined;
  error?: boolean;
  required?: boolean;
  defaultValue?: string | undefined;
  className?: string | undefined;
  getOptions: Options | undefined;
  setOption: React.Dispatch<React.SetStateAction<Options | undefined>>;
  searchById?: (id: string) => void;
};

export default function MetaSelect(props: Props): React.ReactElement {
  const {
    label,
    defaultValue,
    className,
    list,
    name,
    setOption,
    getOptions,
    searchById,
    error,
    required,
  } = props;

  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (event: SelectChangeEvent): void => {
    setOption({
      ...getOptions,
      [name]: event.target.value,
    });

    setValue(event.target.value);

    if (searchById) {
      searchById(event.target.value);
    }
  };

  return (
    <FormControl
      style={{ minWidth: 90, maxWidth: 200 }}
      size="small"
      error={error}
      required={required}
    >
      <InputLabel className="text-[12px]">{label}</InputLabel>
      <Select
        className={`border-0 rounded-[15px] text-[12px] text-left ${className}`}
        value={value}
        label={label}
        onChange={handleChange}
        name={name}
      >
        <MenuItem className="text-[12px]" value="">
          None
        </MenuItem>
        {list &&
          list.map((item) => (
            <MenuItem
              className="text-[12px]"
              key={item.value + item.text}
              value={item.value}
            >
              {item.text.replace("$t:", "")}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
