import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import * as React from "react";

import { AppContext } from "../../context/app-context";
import { useDirectus } from "../../providers/DirectusProvider";
import Collection from "../../services/collection";
import { MetaphoreCollections } from "../../types/collections";
import { MetaphoreLanguageRegions } from "../../types/language-regions";
import { Roles } from "../../types/roles";
import UserRole from "../user-role/user-role";

import MetaSelect from "./parts/meta-select";

interface Fields {
  field?: string | null | undefined;
  meta?:
    | {
        options?: any;
      }
    | null
    | undefined;
}

export type Options = {
  status?: string;
  asset_types?: string;
  metashots_type?: string;
  language_regions_id?: number;
  collection_id?: number;
  category_id?: number;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UploadModal(): React.ReactElement {
  const [toggle, setToggle] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [selectedFile, setSelectedFile] = React.useState<File[]>([]);
  const [previewFiles, setPreviewFiles] = React.useState<any[]>([]);
  const [language, setLanguage] = React.useState<MetaphoreLanguageRegions[]>(
    []
  );
  const [collections, setCollections] = React.useState<MetaphoreCollections[]>(
    []
  );
  const [fields, setFields] = React.useState<Fields[] | undefined>();
  const [options, setOptions] = React.useState<Options>();
  const [categories, setCategories] = React.useState<any[]>([]);
  const [families, setFamilies] = React.useState<any[]>([]);
  const [products, setProducts] = React.useState<any[]>([]);
  const inputRef = React.useRef<HTMLDivElement>(null);
  const { directus } = useDirectus();
  const { category, role, user } = React.useContext(AppContext);
  const { getCollections } = Collection();

  if (role === Roles.regular) {
    return <></>;
  }

  React.useEffect(() => {
    const categoryArr: any[] = [];

    category
      .filter((item) => !item.parent_id)
      .map((item) => categoryArr.push({ text: item.name, value: item.id }));

    setCategories(categoryArr);

    (async () => {
      const languageArr: any = [];
      const newCollectionsArr: any = [];

      const fields = await directus?.fields.readMany("metaphore_assets");
      const language = await directus
        ?.items("metaphore_language_regions")
        .readByQuery();
      const collections = await getCollections();

      language?.data?.map((lang) => {
        languageArr.push({
          text: lang.name,
          value: lang.id,
        });
      });

      if (collections?.length) {
        collections.map((lang) => {
          newCollectionsArr.push({
            text: lang.name,
            value: lang.id,
          });
        });
      }

      if (fields?.data) {
        setFields(fields.data);
        setLanguage(languageArr);
        setCollections(newCollectionsArr);
      }
    })();
  }, [category]);

  const findCategoryById = (id: string): [] => {
    const flattenCategory: any = [];

    category
      .filter((item) => item.parent_id === parseInt(id))
      .map((item) => flattenCategory.push({ text: item.name, value: item.id }));

    return flattenCategory;
  };

  const getListType = (fields: Fields[], type: string): Fields[] => {
    return fields.find((item) => item.field === type)?.meta?.options.choices;
  };

  const handleSearchFamily = (id: string): void => {
    setFamilies(findCategoryById(id));
    setProducts([]);
  };

  const handleSearchProducts = (id: string): void => {
    setProducts(findCategoryById(id));
  };

  const handleToggleModal = (): void => {
    setToggle(!toggle);
    setSelectedFile([]);
    setPreviewFiles([]);
    setError(false);
  };

  const handleUploadFiles = async (): Promise<void> => {
    const formData = new FormData();
    const fileObject = {
      language_regions_id: options?.language_regions_id,
      collection_id: options?.collection_id ? [options.collection_id] : null,
      category_id: options?.category_id,
      status: options?.status,
      asset_types: options?.asset_types,
      metashots_type: options?.metashots_type,
      asset_type: "image",
    };

    if (!options?.asset_types || (!options?.status && role !== Roles.regular)) {
      setError(true);

      return;
    }

    setToggle(!toggle);

    if (selectedFile.length) {
      setLoading(true);

      Array.from(selectedFile).forEach((file) => {
        formData.append("file", file);
      });

      try {
        const token = await directus?.auth.token;
        const result = await axios.post(`${directus?.url}/files`, formData, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        const files = [];

        if (result.data.data.length) {
          result.data.data.map((file: any) => {
            files.push({
              name: file.title,
              image: file.id,
              ...fileObject,
            });
          });
        } else {
          files.push({
            name: result.data.data.title,
            image: result.data.data.id,
            ...fileObject,
          });
        }

        const uploadedFiles = await directus
          ?.items("metaphore_assets")
          .createMany(files);

        // Temporary exclude #29
        // if (role === Roles.regular) {
        //   await directus?.items("metaphore_approval_session").createOne({
        //     assets_ids: JSON.stringify(
        //       uploadedFiles?.data?.map((item) => item.id)
        //     ),
        //     email: user?.email,
        //   });
        // }

        enqueueSnackbar("Files was uploaded.", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("Failed upload.", { variant: "error" });
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const handleSelectFile = (e: any): void => {
    const files: any[] = [];
    const images: any[] = [];

    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    Array.from(e.target.files).forEach((file) => files.push(file));

    setSelectedFile([...selectedFile, ...files]);
    setPreviewFiles([...previewFiles, ...images]);

    inputRef.current?.classList.remove("!bg-cyan-50");
  };

  return (
    <div>
      <Button
        className="!border-none !bg-white shadow rounded-[15px] px-[20px] text-[15px] font-bold h-full"
        endIcon={<FileUploadIcon />}
        onClick={handleToggleModal}
        disabled={loading}
      >
        UPLOAD
        {loading && (
          <LinearProgress className="absolute bottom-[10px] left-[20px] right-[20px]" />
        )}
      </Button>
      <BootstrapDialog
        onClose={handleToggleModal}
        aria-labelledby="customized-dialog-title"
        open={toggle}
        className="rounded-[15px]"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Upload assests
          <IconButton
            className="absolute right-[8px] top-[8px] text-grey-500"
            onClick={handleToggleModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error">
              Please fill out the mandatory fields before submitting.
            </Alert>
          )}
          <div className="w-full flex items-center flex-row text-center relative gap-[10px] flex-wrap max-h-[375px] overflow-auto">
            {previewFiles.map((file, index) => (
              <div key={file + index} className="relative z-10">
                <img
                  className="max-w-[100px] mb-[15px]"
                  key={index}
                  src={file}
                />
                <IconButton
                  className="absolute right-[-5px] top-[-5px]"
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setPreviewFiles(
                      previewFiles.filter((item) => item !== file)
                    );
                    setSelectedFile(
                      selectedFile.filter((item, idx) => idx !== index)
                    );
                  }}
                >
                  <HighlightOffIcon fontSize="inherit" />
                </IconButton>
              </div>
            ))}
          </div>
          <div
            ref={inputRef}
            style={{
              background: "#F6F6F6",
              height: previewFiles.length ? "150px" : "350px",
            }}
            className="w-full rounded-[10px] flex items-center flex-col text-center relative"
          >
            <CloudUploadOutlinedIcon
              style={{
                fontSize: previewFiles.length ? "40px" : "170px",
                marginTop: previewFiles.length ? "20px" : "30px",
              }}
              className="text-slate-300"
            />
            Drag and Drop assets here or
            <Button className="text-[14px] mt-10 px-10 capitalize text-slate-600 border-slate-600 rounded-2xl border-solid border-[1px]">
              Browse
            </Button>
            <input
              onDragEnter={() => {
                inputRef.current?.classList.add("!bg-cyan-50");
              }}
              onDragLeave={() => {
                inputRef.current?.classList.remove("!bg-cyan-50");
              }}
              onChange={handleSelectFile}
              multiple
              accept="image/*, application/pdf, video/*, application/photoshop"
              className="z-0 absolute top-0 left-0 w-full bottom-0 opacity-0 cursor-pointer"
              type="file"
            />
          </div>
          <div className="mt-[15px]">
            <div>{selectedFile.length} files</div>
            <Divider className="my-10" />
            <span className="text-[14px] mr-[10px]">
              Please enter Asset details below
            </span>
            <span className="text-[10px]">*Mandatory</span>
            {fields && (
              <div className="mt-[15px]">
                <MetaSelect
                  error={error}
                  required
                  className="mr-10 mb-10  w-[115px]"
                  label="Asset Types"
                  name="asset_types"
                  getOptions={options}
                  setOption={setOptions}
                  list={getListType(fields, "asset_types")}
                />
                <UserRole>
                  <MetaSelect
                    error={error}
                    required
                    className="mr-10 w-[90px]"
                    label="Status"
                    name="status"
                    getOptions={options}
                    setOption={setOptions}
                    list={getListType(fields, "status")}
                  />
                </UserRole>
                <MetaSelect
                  className="mr-10 w-[125px]"
                  label="Metashot type"
                  name="metashots_type"
                  getOptions={options}
                  setOption={setOptions}
                  list={getListType(fields, "metashots_type")}
                />
                <MetaSelect
                  className="mr-10 w-[155px]"
                  label="Language & Regions"
                  name="language_regions_id"
                  getOptions={options}
                  setOption={setOptions}
                  list={language}
                />
                <MetaSelect
                  className="mr-10 w-[110px]"
                  label="Collections"
                  name="collection_id"
                  getOptions={options}
                  setOption={setOptions}
                  list={collections}
                />
                <MetaSelect
                  className="mr-10 w-[130px]"
                  label="Asset Category"
                  name="category_id"
                  getOptions={options}
                  setOption={setOptions}
                  searchById={handleSearchFamily}
                  list={categories}
                />
                {families.length > 0 && (
                  <MetaSelect
                    className="mr-10 w-[130px]"
                    label="Product Family"
                    name="category_id"
                    getOptions={options}
                    setOption={setOptions}
                    searchById={handleSearchProducts}
                    list={families}
                  />
                )}
                {products.length > 0 && (
                  <MetaSelect
                    className="mr-10 w-[140px]"
                    label="Product Category"
                    name="category_id"
                    getOptions={options}
                    setOption={setOptions}
                    list={products}
                  />
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleToggleModal}>
            Cancel
          </Button>
          <Button disabled={!previewFiles.length} onClick={handleUploadFiles}>
            Upload
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
