import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import React from 'react';

import { AppContext } from '../../context/app-context';

type Props = {
    label: string;
};

export default function Search(props: Props): React.ReactElement {
    const { label } = props;
    const { filter, setFilter } = React.useContext(AppContext);
    
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFilter({ 
            ...filter,
            name: { 
                _icontains: `%${event.target.value}%`
            }
        });
    };

    return (
        <div className='relative w-full'>
            <label className="absolute text-[12px] top-[7px] left-[15px] text-slate-500 z-10">
                {label}
            </label>
            <TextField
                className='shadow border-0 rounded-[15px] bg-white w-full'
                placeholder="Enter keyword"
                onChange={handleSearch}
                sx={{ 
                    '& .MuiInputBase-input': { paddingTop: '24px', paddingBottom: '9px' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: '15px' }
                }}
                InputProps={{
                    endAdornment: <SearchIcon className='text-[22px] text-slate-400 cursor-pointer' />,
                }}
            />
        </div>
    );
}
