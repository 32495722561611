import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TreeItem, {
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { IconButton, List, ListSubheader } from "@mui/material";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";

import { AppContext } from "../../context/app-context";
import { MetaphoreBrands } from "../../types/brands";
import { MetaphoreCategories } from "../../types/categories";
import { flatten } from "../../utils/flatten";
import CategoryDialog from "../dialogs/category";
import UserRole from "../user-role/user-role";

type Props = {
  name: string;
  collection: MetaphoreCategories[] | MetaphoreBrands[];
};

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;
  const isEditableCategory = classes.selected.search("editable");

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const [editCategoryDialog, setEditCategoryDialog] =
    React.useState<boolean>(false);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <UserRole>
        {isEditableCategory > 0 && (
          <div
            onClick={() => setEditCategoryDialog(true)}
            className={classes.iconContainer}
          >
            <EditOutlinedIcon className="!text-[15px]" />
          </div>
        )}
      </UserRole>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
      {editCategoryDialog && (
        <CategoryDialog
          title="Edit category"
          hasEdit
          name={label as string}
          id={nodeId}
          isOpen={editCategoryDialog}
          onClose={() => setEditCategoryDialog(false)}
        />
      )}
    </div>
  );
});

function CustomTreeItem(props: TreeItemProps): React.ReactElement {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}

export default function TreeMenu(props: Props): React.ReactElement {
  const { name, collection } = props;
  const [categoryId, setCategoryId] = React.useState<string | number>();
  const [count, setCount] = React.useState<number>(0);
  const [isEditCategorySelected, setIsEditCategorySelected] =
    React.useState<boolean>(false);
  const [createCategoryDialog, setCreateCategoryDialog] =
    React.useState<boolean>(false);
  const section = React.useRef<any>();
  const { setFilter, filter } = React.useContext(AppContext);
  const { category } = React.useContext(AppContext);

  const styles = {
    "& .MuiTreeItem-root": { borderTop: "1px solid silver" },
    "& .MuiCollapse-root": { margin: 0 },
    "& .MuiCollapse-root li > div": { paddingLeft: "30px" },
    "& .MuiCollapse-root li li > div": { paddingLeft: "50px" },
    "& .MuiTreeItem-root > div": {
      flexDirection: "row-reverse",
      padding: "11px 16px",
    },
    "& .MuiTypography-root": { fontSize: "14px!important" },
    '& > .MuiTreeItem-root[aria-expanded="true"] .Mui-selected + ul': {
      background: "#dd21270d",
    },
  };

  const handleOpenCreateCategoryDialog = (): void => {
    setCreateCategoryDialog(true);
  };

  const handleCloseCreateCategoryDialog = (): void => {
    setCreateCategoryDialog(false);
  };

  const handleCategoryChange = (id: string | number): void => {
    const relatedIds: any[] = [];

    category
      .filter((item) => item.parent_id == id)
      .map((child: any) => {
        relatedIds.push(child.id);

        if (relatedIds.length) {
          category
            .filter((item) => relatedIds.includes(item.parent_id))
            .map((child: any) => {
              relatedIds.push(child.id);
            });
        }
      });

    if (!categoryId || categoryId !== id) {
      setCategoryId(id);
    } else {
      setCategoryId(undefined);
      setCount((prev) => ++prev);
    }

    setFilter({
      ...filter,
      category_id:
        id !== categoryId
          ? {
              _in: [id, ...relatedIds],
            }
          : undefined,
    });
  };

  return (
    <List
      sx={{ bgcolor: "background.paper" }}
      className="shadow max-w-[260px] width-full rounded-[15px] overflow-hidden pt-[8px] mb-[25px] ml-[45px]"
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <div className="flex justify-between">
            {name}
            <UserRole>
              <div>
                <IconButton
                  color={isEditCategorySelected ? "error" : undefined}
                  className="h-fit mr-10"
                  size="small"
                  onClick={() =>
                    setIsEditCategorySelected(!isEditCategorySelected)
                  }
                >
                  <EditOutlinedIcon className="!text-[15px]" />
                </IconButton>
                <IconButton
                  className="h-fit"
                  size="small"
                  onClick={handleOpenCreateCategoryDialog}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </div>
            </UserRole>
          </div>
        </ListSubheader>
      }
    >
      <TreeView
        className="flex-row-reverse"
        aria-label="icon expansion"
        defaultCollapseIcon={<ExpandLess className="!text-[22px]" />}
        defaultExpandIcon={
          <ExpandMore ref={section} className="!text-[22px]" />
        }
        sx={styles}
        key={count}
        onNodeSelect={(event: React.SyntheticEvent, nodeId: string): void =>
          handleCategoryChange(nodeId)
        }
      >
        {flatten(collection).map((category) => {
          return (
            <CustomTreeItem
              className="first:border-0"
              key={category.id}
              nodeId={category.id.toString()}
              label={category.name}
              classes={{
                selected: isEditCategorySelected ? "editable" : "",
              }}
            >
              {category.children.map((family: any) => {
                return (
                  <CustomTreeItem
                    key={family.id}
                    nodeId={family.id.toString()}
                    label={family.name}
                    classes={{
                      selected: isEditCategorySelected ? "editable" : "",
                    }}
                  >
                    {family.children.map((product: any) => {
                      if (product.parent_id === family.id) {
                        return (
                          <CustomTreeItem
                            key={product.id}
                            nodeId={product.id.toString()}
                            label={product.name}
                            classes={{
                              selected: isEditCategorySelected
                                ? "editable"
                                : "",
                            }}
                          />
                        );
                      }
                    })}
                  </CustomTreeItem>
                );
              })}
            </CustomTreeItem>
          );
        })}
        <CategoryDialog
          title="Create category"
          isOpen={createCategoryDialog}
          onClose={handleCloseCreateCategoryDialog}
        />
      </TreeView>
    </List>
  );
}
