import { IDirectus } from "@directus/sdk";

import { getDirectusAssetsPath } from "../providers/DirectusProvider";
import { CustomCollections } from "../types";

export const downloadInternalFile = (
  directus: IDirectus<CustomCollections> | undefined,
  id: string
): void => {
  const link = document.createElement("a");

  link.href = `${getDirectusAssetsPath(directus)}/${id}?download`;
  link.download = `${getDirectusAssetsPath(directus)}/${id}?download`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export async function uploadFileBytescale(params: any): Promise<any> {  
  const baseUrl  = "https://api.bytescale.com";
  const path     = `/v2/accounts/${params.accountId}/uploads/url`;
  const entries  = (obj: any): any => Object.entries(obj).filter(([,val]) => (val ?? null) !== null);
  const response = await fetch(`${baseUrl}${path}`, {
      method: "POST",
      body: JSON.stringify(params.requestBody),
      headers: Object.fromEntries(entries({
          "Authorization": `Bearer ${params.apiKey}`,
          "Content-Type": "application/json",
      }))
  });

  const result = await response.json();

  if (Math.floor(response.status / 100) !== 2)
      throw new Error(`Bytescale API Error: ${JSON.stringify(result)}`);

  return result;
}

export async function convertFileBytescale(params: any): Promise<any> {
  const baseUrl  = "https://upcdn.io";
  const path     = `/${params.accountId}/${params.transformation}${params.filePath}`;
  const entries  = (obj: any): any => Object.entries(obj).filter(([,val]) => (val ?? null) !== null);
  const query    = entries(params.querystring.transformationParams[0] ?? {})
                      .flatMap(([k,v]: any[]) => Array.isArray(v) ? v.map(v2 => [k,v2]) : [[k,v]])
                      .map((kv: any) => kv.join("=")).join("&");
  const response = await fetch(`${baseUrl}${path}${query.length > 0 ? "?" : ""}${query}`, {
    method: "GET",
    headers: Object.fromEntries(entries({
      "Authorization": params.apiKey === undefined ? undefined : `Bearer ${params.apiKey}`,
    }))
  });

  if (Math.floor(response.status / 100) !== 2) {
    const result = await response.json();
    throw new Error(`Bytescale API Error: ${JSON.stringify(result)}`);
  }
  
  return await response.blob();
}