import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactElement, useContext, useEffect, useState } from 'react';

import UISelect from '../../../../components/select/select';
import { AppContext } from '../../../../context/app-context';
import { useDirectus } from '../../../../providers/DirectusProvider';
import Asset from '../../../../services/asset';
import { Status } from '../../../../types/enums/status';
import { MetaphoreLanguageRegions } from '../../../../types/language-regions';

type Props = {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Fields {
    field?: string | null | undefined,
    meta?: {
        options?: any;
    } | null | undefined;
}

export default function Filter(props: Props): ReactElement {
    const { setLoading } = props;

    const [action, setAction] = useState<boolean>(false);
    const [fields, setFields] = useState<Fields[] | undefined>([]);
    const [language, setLanguage] = useState<MetaphoreLanguageRegions[]>([]);
    
    const { 
        sorting, 
        filter,
        assets, 
        selectedFilesAll, 
        selectedFiles, 
        setSorting, 
        setSessions,
        setSelectedFiles, 
        setSelectedFilesAll 
    } = useContext(AppContext);
    const { directus } = useDirectus();
    const { getSessions } = Asset(directus);

    useEffect(() => {
        (async () => {
            const languageArr: any = [];

            const fields = await directus?.fields.readMany('metaphore_assets');
            const language = await directus?.items('metaphore_language_regions').readByQuery();

            language?.data?.map((lang) => {
                languageArr.push({
                    text: lang.name,
                    value: lang.id 
                });
            });

            if (fields?.data) {
                setFields(fields.data);
                setLanguage(languageArr);
            }
        })();
    }, []);

    const getListType = (fields: Fields[], type: string): any => {
        return fields.find(item => item.field === type)?.meta?.options.choices;
    };
    
    const handleSortChange = (e: SelectChangeEvent): void => {
        if (e.target.value) {
            setSorting([e.target.value as string]);
        } else {
            setSorting([]);
        }
    };

    const handleSelectAll = (): void => {
        if(!assets) return;

        setSelectedFilesAll(!selectedFilesAll);
        setSelectedFiles(assets.map(asset => asset.id.toString()));

        if (selectedFilesAll) {
            setSelectedFiles([]);
        }
    };

    const handleApplyAction = async (action: string): Promise<void> => {
        if(!selectedFiles?.length) return;

        if (confirm('Are you sure?')) {
            await directus?.items('metaphore_assets').updateMany(selectedFiles, { approval: action });
            setSessions(await getSessions(sorting, filter));
        }

        setLoading(true);
        setAction(!action);
        setSelectedFiles([]);
    };

    return(
        <div className="flex w-full mb-[25px] flex-wrap">
            <div className="block basis-[65%]">
                {fields && (
                    <>
                        <UISelect className="mr-15 mb-15" label="Asset Types" name="asset_types" list={getListType(fields, 'asset_types')} />
                        <UISelect className="mr-15" label="Status" name="status" list={getListType(fields, 'status')} />
                        <UISelect className="mr-15" label="Approval" name="approval" list={getListType(fields, 'approval')} />
                        <UISelect className="mr-15" label="Metashot type" name="metashots_type" list={getListType(fields, 'metashots_type')} />
                        <UISelect className="mr-15 w-[175px]" label="Language & Regions" name="language_regions_id" list={language} />  
                    </>
                )}
            </div>
            <div className="block basis-[35%] text-right">
                <FormControlLabel 
                    sx={{
                        '.MuiFormControlLabel-label': {
                            fontSize: '14px',
                            whiteSpace: 'nowrap'
                        }
                    }} 
                    control={<Checkbox checked={selectedFilesAll} onChange={handleSelectAll} />} 
                    label="Select All" 
                />
                <FormControl style={{ minWidth: 150 }} size="small">
                    <InputLabel className='text-[14px]'>Action</InputLabel>
                    <Select
                        className="border-0 rounded-[15px] text-[14px] text-left mr-15 mb-15"
                        label="Action"
                        open={action}
                        onClick={() => setAction(!action)}
                    >
                        {/* <MenuItem 
                            disabled={!selectedFiles?.length} 
                            onClick={handleDownloadFiles}
                        >
                            Download selected
                        </MenuItem> */}
                        <MenuItem 
                            disabled={!selectedFiles?.length} 
                            onClick={() => handleApplyAction(Status.Rejected)}
                        >
                            Reject selected
                        </MenuItem>
                        <MenuItem 
                            disabled={!selectedFiles?.length} 
                            onClick={() => handleApplyAction(Status.Approved)}
                        >
                            Approve selected
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ minWidth: 100 }} size="small">
                    <InputLabel className='text-[14px]'>Sort by</InputLabel>
                    <Select
                        className="border-0 rounded-[15px] text-[14px] text-left"
                        label="Sort by"
                        value={sorting.length ? sorting[0] : ""}
                        onChange={handleSortChange}
                    >
                        <MenuItem defaultChecked value="-date_created">Newest</MenuItem>
                        <MenuItem value="date_created">Oldest</MenuItem>
                        <MenuItem value="name">Sort A-Z</MenuItem>
                        <MenuItem value="-name">Sort Z-A</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}
