import { Directus, DirectusOptions, IDirectus } from '@directus/sdk';
import * as React from 'react';

import { CustomCollections } from '../types';

interface DirectusProviderProps {
    /** Directus CMS API url. */
    apiUrl: string,
    /** A set of options to pass to the Directus client. */
    options?: DirectusOptions,
    children: React.ReactNode,
  }

interface DirectusContextTpye {
    apiUrl: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    directus: IDirectus<CustomCollections> | undefined
  }

export const DirectusContext = React.createContext<DirectusContextTpye>({
  apiUrl: '',
  directus: undefined
});

export const DirectusProvider = ({ apiUrl, options, children }: DirectusProviderProps): JSX.Element => {
  const value = React.useMemo<DirectusContextTpye>(() => ({
    apiUrl: apiUrl,
    directus: new Directus(apiUrl, options),
  }), [apiUrl]);

  return (
    <DirectusContext.Provider value={value}>
      {children}
    </DirectusContext.Provider>
  );
};

export const useDirectus = (): DirectusContextTpye => React.useContext(DirectusContext);

export function getDirectusAssetsPath(directus: IDirectus<CustomCollections> | undefined): string {
  return `${directus?.url}assets`;
}