export type Flatten = {
	id: string | number;
	status?: string;
	parent_id?: number;
	name?: string;
	children?: any;
};

export const flatten = (Nodes: Flatten[]): Flatten[] => {
    const map: any = {};
    const roots: Flatten[] = []; 
    let node: Flatten; 
    let i: number;
  
    for (i = 0; i < Nodes.length; i += 1) {
        map[Nodes[i].id] = i; 
        Nodes[i].children = [];
    }
    
    for (i = 0; i < Nodes.length; i += 1) {
        node = Nodes[i];

        if (node.parent_id && node.parent_id !== 0) {
            Nodes[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }

    return roots;
};