import { DefaultItem, FileItem, ID } from '@directus/sdk';
import { SelectChangeEvent } from '@mui/material';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../../../context/app-context';
import { useDirectus } from '../../../providers/DirectusProvider';
import Asset from '../../../services/asset';
import { MetaphoreAssets } from '../../../types/assets';
import { Status } from '../../../types/enums/status';

type Props = {
    asset: DefaultItem<MetaphoreAssets> | undefined | null,
    favorite: boolean | undefined,
    currentOffset: number,
    numPages: number,
    pageNumber: number,
    localState: any,
    type: string | undefined,
    selectedVersion: string | undefined,
    filedata: DefaultItem<FileItem<unknown>> | undefined,
    stackVersions: DefaultItem<MetaphoreAssets>[];
    setStackVersions?: React.Dispatch<
        React.SetStateAction<DefaultItem<MetaphoreAssets>[]>
    >;
    setPageNumber: React.Dispatch<SetStateAction<number>>,
    onDocumentLoadSuccess: (value: any) => void,
    setFavorite: (value: boolean) => void,
    setAsset: (value: DefaultItem<MetaphoreAssets> | undefined | null) => void,
    getSiblingAsset: (offset: number) => void,
    handleChangeVersions: (event: SelectChangeEvent) => void,
};

const useDetailPage = (): Props => {
    const [asset, setAsset] = useState<DefaultItem<MetaphoreAssets> | null | undefined>();
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [selectedVersion, setSelectedVersion] = useState<string>('');
    const [favorite, setFavorite] = useState<boolean>();
    const [type, setType] = useState<string>();
    const [stackVersions, setStackVersions] = useState<
        DefaultItem<MetaphoreAssets>[]
    >([]);
    const [filedata, setFiledata] = useState<DefaultItem<FileItem<unknown>> | undefined>();
    const { id, offset } = useParams();
    const [currentId, setCurrentId] = useState<string | undefined>();
    const [currentOffset, setCurrentOffset] = useState<number>(parseInt(offset ?? '1'));
    const navigate = useNavigate();
    const { directus } = useDirectus();
    const { getFileType, getAssetStackVersions } = Asset(directus);
    const { filter, sorting } = useContext(AppContext);
    const location = useLocation();
    const localState = location.state;

    useEffect(() => {
        if (id) {
            (async () => {
                const item = await directus?.items('metaphore_assets').readOne(id);
                const file = await directus?.files.readOne(item?.image as ID);
                setStackVersions((await getAssetStackVersions(id)) ?? []);
                setFiledata(file ?? undefined);

                if (item?.image) {
                    const file = await getFileType(item.image);
                    setType(file?.type);
                }
                
                if (item) {
                    setAsset(() => item);
                    setSelectedVersion(item.id);
                    setFavorite(item.favorite || false);
                }
            })();
        }
    }, []);

    const handleChangeVersions = (event: SelectChangeEvent): void => {
        event.preventDefault();
        
        setSelectedVersion(event.target.value);
        setAsset(localState?.stack.filter((item: any) => item.id == parseInt(event.target.value))[0]);
    };

    const onDocumentLoadSuccess = ({ numPages }: any): void => {
        setNumPages(numPages as number);
    };

    const getSiblingAsset = async (offset: number): Promise<void> => {
        offset && 
        await directus?.items('metaphore_assets').readByQuery({
            id,
            limit: 1, 
            offset: offset - 1, 
            filter: {
                parent_id: {
                    _null: true
                },
                status: {
                    _eq: Status.Published
                },
                ...filter,
            }, 
            sort: sorting,
        }).then(async response => { 
            if (response.data?.length) {
                setCurrentOffset(() => offset);
                setAsset(response.data[0]);   
                setFavorite(response.data[0].favorite || false);
                setCurrentId(response.data[0].id);

                if (response.data[0]?.image) {
                    const file = await getFileType(response.data[0].image);
                    setType(file?.type);
                }
                
                navigate(`/file/${response.data[0].id}/${offset}`);
            }
        });
    };

    return { 
        asset, 
        currentOffset, 
        favorite, 
        type, 
        numPages, 
        pageNumber,
        localState,
        selectedVersion,
        stackVersions,
        filedata,
        setStackVersions,
        setFavorite, 
        setAsset,
        getSiblingAsset,
        onDocumentLoadSuccess, 
        setPageNumber,
        handleChangeVersions
    };
};

export default useDetailPage;