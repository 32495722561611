import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import React, { useContext } from 'react';

import { AppContext } from '../../../../context/app-context';
import { useDirectus } from '../../../../providers/DirectusProvider';
import Asset from '../../../../services/asset';
import Collection from '../../../../services/collection';

type Props = {
    label: string;
    type: number;
};

export default function CollectionSearch(props: Props): React.ReactElement {
    const { label, type } = props;
    const { getCollections, getSharedCollections } = Collection();
    const { directus } = useDirectus();
    const { getAssets } = Asset(directus);
    const { sorting, filter, user, setAssets, setFilterCollections, setSharedCollections, collectionId } = useContext(AppContext);

    const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        switch(type) {
            case 0:
                setFilterCollections(await getCollections(sorting, {
                    ...filter,
                    name: {
                        _icontains: `%${event.target.value}%`
                    }
                }) ?? undefined);

                setSharedCollections(await getSharedCollections(sorting, {
                    ...filter,
                    name: {
                        _icontains: `%${event.target.value}%`
                    }
                }) ?? undefined);
              break;
            case 1:
                setAssets(await getAssets(sorting, {
                    ...filter,
                    collection_id: {
                        metaphore_collections_id: {
                            _eq: collectionId
                        }
                    },
                    name: {
                        _icontains: `%${event.target.value}%`
                    }
                }));
              break;
            default:
              // code block
        }
    };

    return (
        <div className='relative w-full'>
            <label className="absolute text-[12px] top-[7px] left-[15px] text-slate-500 z-10">
                {label}
            </label>
            <TextField
                className='shadow border-0 rounded-[15px] bg-white w-full'
                placeholder="Enter keyword"
                onChange={handleSearch}
                sx={{ 
                    '& .MuiInputBase-input': { paddingTop: '24px', paddingBottom: '9px' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: '15px' }
                }}
                InputProps={{
                    endAdornment: <SearchIcon className='text-[22px] text-slate-400 cursor-pointer' />,
                }}
            />
        </div>
    );
}
