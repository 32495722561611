import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

import { AppContext } from "../../context/app-context";

type Props = {
  label: string;
  name: string;
  list?: any[] | undefined;
  defaultValue?: string | undefined;
  className?: string | undefined;
};

export default function UISelect(props: Props): React.ReactElement {
  const { label, defaultValue, className, list, name } = props;
  const { filter, setFilter } = React.useContext(AppContext);

  const [value, setValue] = React.useState(defaultValue || "");

  React.useEffect(() => {
    if (!Object.keys(filter).length) {
      setValue("");
    }
  }, [filter]);

  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value && event.target.value !== "all") {
      setFilter({
        ...filter,
        [name]: {
          _eq: event.target.value,
        },
      });
    } else {
      setFilter((current) => {
        const filter = { ...current };
        delete (filter as any)[event.target.name];

        return filter;
      });
    }

    setValue(event.target.value);
  };

  return (
    <FormControl style={{ minWidth: 150, maxWidth: 200 }} size="small">
      <InputLabel className="text-[14px]">{label}</InputLabel>
      <Select
        className={`border-0 rounded-[15px] text-[14px] text-left ${className}`}
        value={value}
        label={label}
        onChange={handleChange}
        name={name}
      >
        <MenuItem value="all">All</MenuItem>
        {list &&
          list.map((item) => (
            <MenuItem
              className="capitalize"
              key={item.value + item.text}
              value={item.value}
            >
              {item.text.replace("$t:", "")}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
