import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { Card, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import { format } from 'date-fns';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { enqueueSnackbar } from 'notistack';
import { ReactElement, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';

import { AppContext } from '../../context/app-context';
import { getDirectusAssetsPath, useDirectus } from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { Action } from '../../types/enums/action';
import { Colors } from '../../types/enums/colors';
import { Status } from '../../types/enums/status';

import LeftNavigation from './parts/left-navigation/left-navigation';

export default function ApprovalsAssets(): ReactElement {
    const { directus } = useDirectus();
    const { getApprovalsSessions, getDraftAssets, getFileType, getSessions } = Asset(directus);
    const { sessions, setSessions } = useContext(AppContext);
    
    useEffect(() => {
        (async () => {
            setSessions(await getSessions());
        })();
    }, []);

    const handleAction = async (id: string, action: string): Promise<void> => {
        const draftAsset = (await getDraftAssets(id))?.map(item => item.id);

        if (confirm('Are you sure?') && draftAsset?.length) {
            try {
                switch(action) {
                    case Action.Approve:
                        await directus?.items('metaphore_assets').updateMany(draftAsset, { status: Status.Published });
                        enqueueSnackbar('Files was approved.', { variant: 'success' });
                      break;
                    case Action.Reject:
                        await directus?.items('metaphore_assets').updateMany(draftAsset, { status: Status.Archived });
                        enqueueSnackbar('Files was archived.', { variant: 'success' });
                      break;
                }

                setSessions(await getApprovalsSessions());
            } catch(error) {
                // eslint-disable-next-line no-console
                console.log(error);
                enqueueSnackbar('Somthing was wrong.', { variant: 'error' });
            }
        }
    }

    const handleDownload = async (id: string): Promise<void> => {
        const draftAsset = await getDraftAssets(id);
        const zip = new JSZip();

        if (draftAsset?.length) {
            await Promise.all(draftAsset.map(async (file): Promise<void> => {
                if (file) {
                    const type = await getFileType(file.image);
                    const imageUrl = `${getDirectusAssetsPath(directus)}/${file.image}?download`;
                    const imageBlob = await fetch(imageUrl).then(response => response.blob());
    
                    zip.file(
                        `${file.name}.${type?.substring(type?.indexOf('/') + 1)}`, 
                        imageBlob, 
                        { binary: true }
                    );
                }
            }));
        }

        zip.generateAsync({ type: "blob" }).then(content => saveAs(content, "metahub-archive"));
    }

    return (
        <>
            <div className="flex w-full flex-row gap-[25px]">
                <div className="basis-[320px] shrink-0">
                    <LeftNavigation name="Approvals" sessions={sessions} />
                </div>
                <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex content-start">
                    <div className="flex w-full justify-items-center h-fit">
                        <h2 className="text-[24px] text-rose-600">
                            Approval Sessions
                            <span className="text-[16px] text-slate-400"> ({sessions?.length ?? 0})</span>
                        </h2>
                    </div>
                    <>
                        {sessions?.length ? sessions.map((session) => (
                            <Card 
                                key={session.id}
                                style={{borderColor: '#E8E8E8', boxShadow: 'none'}} 
                                className={`rounded-[15px] border-solid border-1 w-full relative w-[calc(33%_-_10px)] cursor-pointer`}
                            >
                                <CardHeader
                                    title={<div className='max-w-[80%] overflow-hidden text-ellipsis'>{session.email}</div>}
                                    // action={
                                    //     <ContextMenu 
                                    //         id={session.user_created} 
                                    //         actions={{
                                    //             approve: handleAction,
                                    //             reject: handleAction,
                                    //             download: handleDownload,
                                    //         }} 
                                    //     />
                                    // }
                                >
                                </CardHeader>
                                <div className='bg-slate-100 text-center cursor-pointer'>
                                    <Link to={`/approval/${session.id}`} state={{ id: session.id }}>
                                        <PermMediaOutlinedIcon className='text-[150px] m-10 text-slate-400' />
                                    </Link>
                                </div>
                                <CardContent className='p-[20px] leading-[17px] last:pb-[10px]'>
                                    <Typography className='text-[13px] text-slate-400'>
                                        {session.items.length} Assests
                                    </Typography>
                                    <div className='text-[12px] justify-between flex'>
                                        <div>Review Status:</div>
                                        <div>Publishing Status:</div>
                                    </div>
                                    <div className='text-[13px] text-slate-400 justify-between flex'>
                                        <div>
                                            {session.items.filter((item: any) => item.approval === 'approved').length}/{session.items.length}
                                            <span style={{ background: Colors.Approved }} className="w-[10px] h-[10px] color-[#000000] inline-block border-1 border-slate-600 mx-[5px] rounded-full"></span> 
                                            Approved
                                            <br/>
                                            Date Created: {format(new Date(session.date_created), 'dd-MM-yyyy')}
                                        </div>
                                        <div>
                                            <Tooltip title="Delete">
                                                <>
                                                    {session.items.filter((item: any) => item.status === 'archived').length}/{session.items.length}
                                                    <span style={{ background: Colors.Archived }} className="w-[10px] h-[10px] color-[#000000] inline-block border-1 border-slate-600 mx-[5px] rounded-full"></span> 
                                                    Archived
                                                    <br/>
                                                    {session.items.filter((item: any) => item.status === 'published').length}/{session.items.length}
                                                    <span style={{ background: Colors.Published }} className="w-[10px] h-[10px] color-[#000000] inline-block border-1 border-slate-600 mx-[5px] rounded-full"></span> 
                                                    Published
                                                </>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>)
                        ) : 'Nothing for approvals'}
                    </>
                </div>
            </div>
        </>
    );
}