import SendIcon from "@mui/icons-material/Send";
import { Button, IconButton, TextField } from "@mui/material";
import React from "react";

import { useDirectus } from "../../../providers/DirectusProvider";
import { MetaphoreComments } from "../../../types/comment";

interface ReplyCommentProps {
  comment: MetaphoreComments;

  refresh: () => void;
}

export function ReplayComment(props: ReplyCommentProps): React.ReactElement {
    const { comment, refresh } = props;
  
    const [isOpen, setIsOpen] = React.useState(false);
    const commentRef = React.useRef<HTMLInputElement>();
  
    const { directus } = useDirectus();
  
    const handleReplayComment = async (): Promise<void> => {
      if (!commentRef.current?.value) return;
  
      await directus?.items("metaphore_comments").createOne({
        asset_id: comment.asset_id,
        comment: commentRef.current.value,
        parent_id: comment.id as string,
      });
  
      commentRef.current.value = "";
      setIsOpen(false);
      refresh();
    };
  
    return (
      <div className="mb-5">
        <Button onClick={() => setIsOpen(!isOpen)} size="small">
          Reply
        </Button>
        {isOpen && (
          <div className="flex mt-5">
            <TextField
              inputRef={commentRef}
              size="small"
              focused
              autoFocus
              label="Your comment"
              placeholder="Type something here…"
              className="w-full mr-5"
            />
            <IconButton onClick={() => handleReplayComment()} size="medium">
              <SendIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
