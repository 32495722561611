import { ID } from "@directus/sdk";
import React from "react";

import { AppContext } from "../context/app-context";
import { useDirectus } from "../providers/DirectusProvider";
import { MetaphoreComments } from "../types/comment";

interface OutputCommentsProps {
    commentText: string | undefined,
    comments: MetaphoreComments[] | undefined,
    editCommentId: string | number | undefined,
    refresh: boolean,

    setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
    setEditCommentId: React.Dispatch<React.SetStateAction<string | number | undefined>>,
    setCommentText: React.Dispatch<React.SetStateAction<string | undefined>>,

    removeComment: (id: number | string) => Promise<void>,

    handleRemoveComment: (id: number | string) => Promise<void>,
    handleEditComment: (id: number | string, comment: string) => Promise<void>,
    handleAddComment: () => Promise<void>,
}

export const useComments = (assetId: string): OutputCommentsProps => {
    const { directus } = useDirectus();
    const { user } = React.useContext(AppContext);

    const [commentText, setCommentText] = React.useState<string | undefined>();
    const [comments, setComments] = React.useState<MetaphoreComments[]>();
    const [editCommentId, setEditCommentId] = React.useState<string | number>();
    const [refresh, setRefresh] = React.useState(false);

    const getComments = async (): Promise<MetaphoreComments[] | undefined> => {
        const comments = await directus?.items("metaphore_comments").readByQuery({
            filter: {
                asset_id: assetId,
            },
            sort: ["date_created"],
        });

        return comments?.data ?? undefined;
    };

    const setComment = async (): Promise<void> => {
        if (!user) return;

        await directus?.items("metaphore_comments").createOne({
            asset_id: assetId,
            comment: commentText,
        });
    };

    const updateComment = async (
        id: number | string,
        comment: string
    ): Promise<void> => {
        if (!user) return;

        await directus?.items("metaphore_comments").updateOne(id, {
            id,
            comment,
        });
    };

    const removeComment = async (id: string | number): Promise<void> => {
        if (!user) return;

        if (confirm("Are you sure?")) {
            // Get subcomments
            const relatedComments = comments
                ?.filter((c) => c.parent_id == id)
                .map((c) => c.id);

            await directus?.items("metaphore_comments").deleteOne(id);
            await directus
                ?.items("metaphore_comments")
                .deleteMany(relatedComments ?? []);

            setComments((prev) => prev?.filter((c) => c.id != id));
        }
    };

    React.useEffect(() => {
        (async () => {
            const initialCommets = await getComments();
            const userIds = initialCommets
                ?.map((c) => c.user_created)
                .filter((value, index, array) => array.indexOf(value) === index);

            const users = userIds?.length
                ? await directus?.users.readMany(userIds as ID[])
                : undefined;

            const comments = initialCommets?.map((c) => {
                const user = users?.data?.find((u) => u.id === c.user_created);

                if (user) {
                    return {
                        ...c,
                        fname: user.first_name,
                        lname: user.last_name,
                        email: user.email,
                    };
                }
            }) as MetaphoreComments[];

            setComments(comments);
        })();

        setEditCommentId(undefined);
    }, [refresh]);

    const handleRemoveComment = async (id: string | number): Promise<void> => {
        await removeComment(id);
    };

    const handleEditComment = async (
        id: string | number,
        comment: string
    ): Promise<void> => {
        await updateComment(id, comment);
    
        setEditCommentId(undefined);
        setComments((prev) =>
            prev?.map((c) =>
            c.id == id
                ? {
                    ...c,
                    comment: comment,
                }
                : c
            )
        );
    };

    const handleAddComment = async (): Promise<void> => {
        await setComment();
    
        setRefresh(!refresh);
        setCommentText(undefined);
    };
  
    return {
        commentText,
        comments,
        editCommentId,
        refresh,
        setRefresh,
        setEditCommentId,
        setCommentText,
        removeComment,
        handleRemoveComment,
        handleEditComment,
        handleAddComment
    };
}