import {
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { AppContext } from "../../context/app-context";
import { useDirectus } from "../../providers/DirectusProvider";
import { Flatten, flatten } from "../../utils/flatten";

export interface CreateCategoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  name?: string;
  id?: string;
  hasEdit?: boolean;
}

export default function CategoryDialog(
  props: CreateCategoryDialogProps
): React.ReactElement {
  const { onClose, isOpen, title, hasEdit, name, id } = props;

  const [categoryName, setCategoryName] = React.useState<string | undefined>(
    name ?? undefined
  );
  const [parentId, setParentId] = React.useState<number>();
  const { directus } = useDirectus();
  const { category, setCategory } = React.useContext(AppContext);
  const [orderIndex, setOrderIndex] = React.useState<string>(
    category?.find((c) => c.id == id)?.order_index ?? "0"
  );

  // Collect categories items
  const categoriesItems = [];

  categoriesItems.push(
    <MenuItem className="text-[12px]" key="none" value="">
      None
    </MenuItem>
  );

  flatten(category).map((item: Flatten) => {
    categoriesItems.push(
      <MenuItem className="text-[12px]" key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );

    if (item.children) {
      item.children.map((family: Flatten) => {
        categoriesItems.push(
          <MenuItem
            className="text-[12px] pl-[30px]"
            key={family.id}
            value={family.id}
          >
            {family.name}
          </MenuItem>
        );
      });
    }
  });

  const handleCreateCategory = async (): Promise<void> => {
    const newCategory = await directus
      ?.items("metaphore_categories")
      .createOne({
        name: categoryName,
        parent_id: parentId,
        order_index: orderIndex,
      });

    if (newCategory) {
      setCategory([...category, newCategory]);
    }

    if (newCategory) {
      const updatedCategories = await directus
        ?.items("metaphore_categories")
        .readByQuery({ limit: -1, sort: ["order_index", "name"] });

      setCategory(updatedCategories?.data ?? []);
    }

    setParentId(undefined);
    setCategoryName(undefined);
    onClose();
  };

  const handleEditCategory = async (): Promise<void> => {
    if (!id) {
      return;
    }

    const updatedCategory = await directus
      ?.items("metaphore_categories")
      .updateOne(id, {
        name: categoryName,
        order_index: orderIndex,
      });

    if (updatedCategory) {
      const updatedCategories = await directus
        ?.items("metaphore_categories")
        .readByQuery({ limit: -1, sort: ["order_index", "name"] });

      setCategory(updatedCategories?.data ?? []);
    }

    setCategoryName(name);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="min-w-[300px]">
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCategoryName(e.target.value)
          }
          autoFocus
          focused
          margin="dense"
          id="name"
          type="text"
          fullWidth
          placeholder={`${title}...`}
          variant="standard"
          value={categoryName ?? ""}
        />
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value < "1") {
              setOrderIndex("1");
            } else {
              setOrderIndex(e.target.value);
            }
          }}
          margin="dense"
          id="name"
          label="Order index"
          type="number"
          variant="standard"
          value={orderIndex}
        />
        <br />
        {!hasEdit && (
          <FormControl className="min-w-[150px] mt-15" size="small">
            <InputLabel className="text-[12px]">Parent Category</InputLabel>
            <Select
              className="border-0 rounded-[15px] text-[12px] text-left"
              value={parentId ?? ""}
              label="Parent Category"
              onChange={(e) =>
                setParentId((e.target.value as number) || undefined)
              }
            >
              {categoriesItems}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!categoryName || categoryName.length < 3}
          onClick={hasEdit ? handleEditCategory : handleCreateCategory}
        >
          {hasEdit ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
