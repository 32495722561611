import { DefaultItem } from "@directus/sdk";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import { MetaphoreAssets } from "../../types/assets";

export interface CreateCategoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
  asset: DefaultItem<MetaphoreAssets>;
}

export default function PreviewAsset(
  props: CreateCategoryDialogProps
): React.ReactElement {
  const { onClose, isOpen, asset } = props;
  const { directus } = useDirectus();

  const [isPreviewImagesLoaded, setIsPreviewImagesLoaded] =
    React.useState<boolean>(false);

  if (!asset) {
    return <></>;
  }

  const src = `${getDirectusAssetsPath(directus)}/${
    asset.image
  }?width=800&quality=90&fit=inside`;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "100%",
          minHeight: "100%",
          background: "none",
        },
      }}
    >
      <DialogContent
        onClick={onClose}
        className="text-center flex justify-center items-center flex-wrap"
      >
        <>
          {!isPreviewImagesLoaded && <CircularProgress />}
          <img
            onLoad={() => setIsPreviewImagesLoaded(true)}
            className={`${isPreviewImagesLoaded ? "inline-block" : "hidden"}`}
            src={src}
            title={asset.name}
          />
        </>
      </DialogContent>
    </Dialog>
  );
}
