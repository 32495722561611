import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import Filter from "../../components/filter/filter";
import LayoutSwitcher from "../../components/layout-switcher/layout-switcher";
import { AppContext } from "../../context/app-context";
import Navigation from "../../layout/navigation";
import { useDirectus } from "../../providers/DirectusProvider";
import Collection from "../../services/collection";
import { MetaphoreCollections } from "../../types/collections";
import { ListLayout } from "../files/files";

import "./collections.css";
import CollectionCard from "./parts/collection-card/collection-card";
import CollectionItems from "./parts/collection-items/collection-items";
import CollectionList from "./parts/collection-list/collection-list";
import CollectionListItem from "./parts/collection-list/collection-list-item";
import CollectionFilter from "./parts/filter/filter";
import Header from "./parts/header/header";
import LeftNavigation from "./parts/left-navigation/left-navigation";
import Search from "./parts/search/search";
import SharedCollections from "./parts/shared-collections/shared-collections";

export enum SearchTypes {
  collection,
  asset,
}

export default function Collections(): ReactElement {
  const [currentCollection, setCurrentCollection] = useState<
    MetaphoreCollections | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const { directus } = useDirectus();
  const {
    getCollections,
    getCollectionById,
    getCollectionItemsById,
    getSharedCollections,
    createCollection,
  } = Collection();
  const [openDialog, setOpenDialog] = useState(false);
  const [inputElement, setInputElement] = useState<string>("");
  const { id } = useParams();
  const {
    assets,
    sorting,
    filter,
    collectionId,
    collections,
    filterCollections,
    sharedCollections,
    setSorting,
    setCollectionId,
    setFilter,
    setAssets,
    setCollections,
    setFilterCollections,
    setSharedCollections,
  } = useContext(AppContext);
  const [cookies] = useCookies();

  useEffect(() => {
    // setLoading(true);

    (async () => {
      if (collectionId) {
        setCurrentCollection(
          (await getCollectionById(collectionId)) ?? undefined
        );
        setAssets(await getCollectionItemsById(collectionId));
      }
      setLoading(false);
    })();
  }, [collectionId, collections]);

  useEffect(() => {
    setCollectionId(undefined);

    (async () => {
      if (id) {
        await directus
          ?.items("metaphore_external_links")
          .readOne(id)
          .then((link) => {
            if (link) {
              setCollectionId(link.collection_id);
            }
          });
      }

      if (!collectionId) {
        setCollections((await getCollections(["name"])) ?? undefined);
        setSharedCollections(
          (await getSharedCollections(["name"])) ?? undefined
        );
        setLoading(false);
      }
    })();

    setFilter({});
    setAssets([]);
    setSorting(["-date_created"]);
  }, []);

  useEffect(() => {
    (async () => {
      if (!collectionId) {
        setFilterCollections((await getCollections(sorting)) ?? undefined);
        setSharedCollections(
          (await getSharedCollections(sorting)) ?? undefined
        );
      } else {
        setAssets(await getCollectionItemsById(collectionId, filter, sorting));
      }
    })();
  }, [sorting, filter]);

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleCreateCollection = async (): Promise<void> => {
    const user = await directus?.users.me.read();

    if (user?.id) {
      await createCollection(inputElement, user.id);

      setFilterCollections((await getCollections(sorting)) ?? undefined);
      setCollections((await getCollections(["name"])) ?? undefined);
    }

    setOpenDialog(false);
  };

  return (
    <>
      <Navigation classes="!pl-[345px]">
        {collectionId ? (
          <Search label="Search in collection" type={SearchTypes.asset} />
        ) : (
          <Search label="Search collections" type={SearchTypes.collection} />
        )}
        <LayoutSwitcher />
        {!collectionId && (
          <Button
            className="!border-none !bg-white shadow rounded-[15px] px-[20px] text-[15px] font-bold h-full w-[260px]"
            startIcon={<AddIcon />}
            onClick={handleOpenDialog}
          >
            NEW COLLECTION
          </Button>
        )}
      </Navigation>
      <div className="flex w-full flex-row gap-[25px]">
        <div className="basis-[320px] shrink-0">
          <LeftNavigation
            name="Collections"
            collections={collections}
            sharedCollections={sharedCollections}
          />
        </div>
        <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex content-start">
          {collectionId ? (
            <>
              <Header
                title={currentCollection?.name || ""}
                amount={assets?.length ?? 0}
                collection={
                  collections?.filter((item) => item.id === collectionId)[0] ??
                  undefined
                }
              />
              <Filter collectionAssets />
            </>
          ) : (
            <>
              <Header
                title="Your Collections"
                amount={collections?.length ?? 0}
                collection={
                  collections?.filter((item) => item.id === collectionId)[0] ??
                  undefined
                }
              />
              <CollectionFilter />
            </>
          )}
          {loading ? (
            <Box className="flex w-full justify-center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {collectionId ? (
                <CollectionItems collectionAssets={assets ?? undefined} />
              ) : (
                <>
                  <div className="flex gap-20 flex-wrap w-full">
                    {cookies.listLayout === ListLayout.Grid ? (
                      filterCollections?.map((collection) => (
                        <CollectionCard
                          key={collection.id}
                          collection={collection}
                          setLoading={setLoading}
                        />
                      ))
                    ) : (
                      <CollectionList>
                        {filterCollections?.map((collection) => (
                          <CollectionListItem
                            key={collection.id}
                            collection={collection}
                            setLoading={setLoading}
                          />
                        ))}
                      </CollectionList>
                    )}
                  </div>
                  <SharedCollections setLoading={setLoading} />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add new collection</DialogTitle>
        <DialogContent className="min-w-[300px]">
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputElement(e.target.value)
            }
            autoFocus
            focused
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            placeholder="Enter collection name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            disabled={!inputElement.replace(/\s/g, "").length}
            onClick={handleCreateCollection}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
