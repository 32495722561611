import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactElement, useContext } from 'react';

import { AppContext } from '../../../../context/app-context';

export default function CollectionFilter(): ReactElement {
    const { sorting, setSorting } = useContext(AppContext);
    
    const handleSortChange = (e: SelectChangeEvent): void => {
        if (e.target.value) {
            setSorting([e.target.value as string]);
        } else {
            setSorting([]);
        }
    };

    return(
        <div className="flex w-full mb-[25px] flex-wrap">
            <div className="block basis-[65%]">
                {/* <UISelect className="mr-15" label="Shared with" name="shared" /> */}
            </div>
            <div className="block basis-[35%] text-right">
                <FormControl style={{ minWidth: 100 }} size="small">
                    <InputLabel className='text-[14px]'>Sort by</InputLabel>
                    <Select
                        className="border-0 rounded-[15px] text-[14px] text-left"
                        label="Sort by"
                        value={sorting.length ? sorting[0] : ""}
                        onChange={handleSortChange}
                    >
                        <MenuItem defaultChecked value="-date_created">Newest</MenuItem>
                        <MenuItem value="date_created">Oldest</MenuItem>
                        <MenuItem value="name">Sort A-Z</MenuItem>
                        <MenuItem value="-name">Sort Z-A</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}
