import { DefaultItem } from "@directus/sdk";
import { format } from "date-fns";
import React, { ReactElement, useState } from "react";

import CardBox from "../../components/card/card-box";
import { AppContext } from "../../context/app-context";
import { useDirectus } from "../../providers/DirectusProvider";
import { MetaphoreAssets } from "../../types/assets";

export default function Home(): ReactElement {
    const [assets, setAssets] = useState<DefaultItem<MetaphoreAssets>[] | undefined | null>();
    const [user, setUser] = useState<any>();
    const { lastAccess } = React.useContext(AppContext);
    const { directus } = useDirectus();

    React.useEffect(() => {
        (async () => {
            await directus?.items('metaphore_assets').readByQuery(
                {  
                    limit: 4,
                    meta: "*",
                    sort: ["-date_created"]
                }).then(response => {
                    if (response.data) {
                        setAssets(response.data);
                    }
                });

            const userData = await directus?.users.me.read();
            setUser(userData);
        })();
    }, []);
    
    return (
        <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] flex ml-[45px]">
            <div className="basis-[60%] flex-wrap flex items-center justify-center flex-col">
                <h2 className="text-[46px] font-normal text-center">
                    Bem-vindo (a) <br /> {user?.first_name} {user?.last_name}
                </h2>
                <div className="flex-column text-[20px] pt-20">Last login: {lastAccess && (format(new Date(lastAccess), 'dd-MM-yyyy'))}</div>
                <div className="max-w-[700px] mt-[50px] text-[20px]">
                    <p className="mb-[20px]">Olá! Bem-vindo (a) a biblioteca de assets Bauducco.</p>
                    <p className="mb-[20px]">Na barra de menu a esquerda você encontrará uma visão geral do conteúdo disponível que pode ser filtrado por categoria e/ou tipo de arquivo.</p>
                    <p className="mb-[20px]">Clique nas imagens para ampliar, marcar como favorita ou baixar.</p>
                </div>
            </div>
            <div className="basis-[40%] flex-wrap flex gap-[20px]">
                <h2 className="w-full text-18 font-bold">Latest Assets</h2>
                {assets?.map((asset, index) => <CardBox classes="!max-w-[47%] !w-full" key={asset.id} isCheckbox={false} asset={asset} offset={index + 1} />)}
            </div>
        </div>
    );
}