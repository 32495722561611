import { Alert, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import MetaphoreLogo from '../../assets/media/metaphore-logo.png';
import { useDirectus } from '../../providers/DirectusProvider';

export default function RequestPassword(): React.ReactElement {
    const [success, setSuccess] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const { directus } = useDirectus();

    const validateEmail = (email: string): boolean => {
        return new RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm").test(email);
    }

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value);
    }
    
    const handleRequestPassword = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();

        await directus?.auth.password.request(email, process.env.REACT_APP_PASSWORD_RESET_URL_ALLOW);

        setSuccess(true);
    }

    return (
        <div style={{ minHeight: 'inherit', background: '#f6f6f6' }}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img className='max-w-full mb-[120px] mt-[80px] mb-[40px] max-w-[300px]' src={MetaphoreLogo} alt='Company Logo' />
                    <Box 
                        sx={{ boxShadow: '0px 10px 15px #ccc', borderColor: '#dc2227', padding: '20px 40px 30px 40px' }}
                        className='bg-white border-t-4 w-[500px]' 
                        component="form" 
                        noValidate 
                        onSubmit={handleRequestPassword}
                    >
                        <Typography 
                            className="mb-[35px] mt-10 font-['Open_Sans']"
                            component="h1" 
                            variant="h5"
                        >
                            Please enter email address
                        </Typography>
                        {success ? (
                            <Alert className='w-full mb-20'>
                                Your submissions has been received. <br/>
                                If we have an account matching your email address, you will receive an email with a link to reset your password.
                            </Alert>
                        ) : (
                            <>
                                <InputLabel className='text-[12px]'>E-mail</InputLabel>
                                <TextField
                                    className='m-0 mb-10 bg-[#f6f6f6]'
                                    required
                                    fullWidth
                                    type="text"
                                    variant="standard"
                                    sx={{ '& .MuiInputBase-input': { padding: '7px 5px' } }}
                                    onChange={handleEmailInput}
                                />
                                <Button
                                    className="mt-20 mb-0 p-2 bg-[#DC2227] hover:bg-[#DC2227] text-white rounded-none capitalize"
                                    fullWidth
                                    variant="outlined"
                                    disabled={!validateEmail(email)}
                                    onClick={handleRequestPassword}
                                >
                                    Send
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </div>
    );
}