type Props = {
    children:  React.ReactNode;
};

export default function CollectionList(props: Props): React.ReactElement {
    const { children } = props;

    return (
        <section className='w-full'>
            <header className='flex mb-[25px] px-10'>
                <div className='flex-1 pl-10' />
                <div className='flex-1 border-slate-400 border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Collection Name</div>
                <div className='flex-1 border-slate-400 border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Amount Assets</div>
                <div className='flex-1 border-slate-400 border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Last modified</div>
                <div className='flex-1 pl-10' />
            </header>
            {children}
        </section>
    );
}

CollectionList.defaultProps = {
    classes: undefined,
};