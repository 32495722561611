
import { IDirectus } from "@directus/sdk";

import { CustomCollections } from "../types";

interface User {
    getUserRole: () => Promise<any>;
    getUsers: () => Promise<any>;
}

const User = (directus: IDirectus<CustomCollections> | undefined): User => {
    const getUserRole = async (): Promise<any> => {
        try {
            const user = await directus?.users.me.read();

            if (user?.role) {
                const userRole = await directus?.roles.readOne(user.role);
                
                return userRole?.name;
            }
            
            return undefined;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }
    
    const getUsers = async (): Promise<any> => {
        try {
            const users = await directus?.users.readByQuery();

            return users?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    return {
        getUserRole,
        getUsers
    };
};

export default User;
