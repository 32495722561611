import { LogoutOutlined } from "@mui/icons-material";
import React, { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";

import ExampleLogo from "../assets/media/Bauducco_logo_header.png";
import CompanyLogo from "../assets/media/logo.svg";
import { AppContext } from "../context/app-context";
import { useDirectus } from "../providers/DirectusProvider";

export default function Header(): ReactElement {
  const { directus } = useDirectus();
  const { setToken } = React.useContext(AppContext);
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    await directus?.auth.refreshIfExpired();
    await directus?.auth.logout();

    setToken(null);
    navigate(`/`);
  };

  return (
    <div className="mb-30 flex w-full flex-row justify-between pl-[45px]">
      <div className="flex items-center">
        <div className="pt-5 pb-15 pr-15 border-r border-neutral-400 relative">
          <Link to="/">
            <img className="max-h-full" src={CompanyLogo} alt="Company Logo" />
          </Link>
          <span className="text-[10px] absolute right-16 bottom-5 font-bold text-gray-400">
            METAHUB
          </span>
        </div>
        <img
          style={{ maxWidth: "150px" }}
          className="max-h-full"
          src={ExampleLogo}
          alt="Company Logo"
        />
      </div>
      <div className="flex flex-row gap-30">
        {/* <button className='flex cursor-pointer flex-row items-center gap-10' type='button'>
                    <p className='text-base uppercase text-companyDarkGrey'>
                        <Link to="/account">Account</Link>
                    </p>
                    <PersonOutline className='fill-companyDarkGrey' />
                </button>
                <button className='flex cursor-pointer flex-row items-center gap-10' type='button'>
                    <p className='text-base uppercase text-companyDarkGrey'>
                        <Link to="/settings">Settings</Link>
                    </p>
                    <SettingsOutlined className='fill-companyDarkGrey' />
                </button> */}
        <button
          onClick={handleLogout}
          className="flex cursor-pointer flex-row items-center gap-10"
          type="button"
        >
          <p className="text-base uppercase text-companyDarkGrey">Logout</p>
          <LogoutOutlined className="fill-companyDarkGrey" />
        </button>
      </div>
    </div>
  );
}
