import { ItemInput, UserItem } from "@directus/sdk";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import { MetaphoreComments } from "../../../types/comment";

interface ListCommentsProps {
  comment: MetaphoreComments;
  user: ItemInput<UserItem<unknown>> | undefined;

  setEditCommentId: (id: string | number | undefined) => void;

  handleRemoveComment: (id: string | number) => void;
}

export function ListComments(props: ListCommentsProps): React.ReactElement {
  const { comment, user, setEditCommentId, handleRemoveComment } = props;

  return (
    <ListItem className="px-0 pb-0" alignItems="flex-start">
      <ListItemText
        primary={
          <span className="flex align-middle justify-between">
            {comment.email}
            <div className="text-[11px]">
              {format(new Date(comment.date_created), "dd/MM/yyyy HH:mm")}
              {user?.id === comment.user_created && (
                <>
                  <IconButton
                    onClick={() => setEditCommentId(comment.id)}
                    className="ml-5"
                    color="error"
                    size="small"
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveComment(comment.id)}
                    className="ml-5"
                    color="error"
                    size="small"
                  >
                    <DeleteOutlineIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </div>
          </span>
        }
        secondary={
          <>
            {(comment.fname || comment.lname) && (
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {comment.fname} {comment.lname} -
              </Typography>
            )}
            {` ${comment.comment}`}
          </>
        }
      />
    </ListItem>
  );
}
