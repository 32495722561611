import SendIcon from "@mui/icons-material/Send";
import { Divider, IconButton, List, TextField } from "@mui/material";
import React, { ReactElement, useState } from "react";

import { EditComment } from "../../../components/comments/parts/edit-comment";
import { ListComments } from "../../../components/comments/parts/list-comments";
import { ReplayComment } from "../../../components/comments/parts/reply-comment";
import { SubComments } from "../../../components/comments/parts/sub-comments";
import { AppContext } from "../../../context/app-context";
import { useComments } from "../../../hooks/useComments";

type Props = {
  assetId: string;
};

export default function ActivityFeed(props: Props): ReactElement {
  const { assetId } = props;

  const [comment, setComment] = useState<string>();

  const {
    commentText,
    comments,
    editCommentId,
    refresh,
    setCommentText,
    setEditCommentId,
    setRefresh,
    removeComment,
    handleRemoveComment,
    handleEditComment,
    handleAddComment,
  } = useComments(assetId);

  const { user } = React.useContext(AppContext);

  return (
    <div>
      <List className="pt-0 w-full p-0 mb-[-1px]">
        {comments &&
          comments.map(
            (comment, index, array) =>
              !comment.parent_id && (
                <div key={comment.date_created}>
                  <ListComments
                    comment={comment}
                    setEditCommentId={setEditCommentId}
                    user={user}
                    handleRemoveComment={handleRemoveComment}
                  />
                  <EditComment
                    comment={comment}
                    editCommentId={editCommentId}
                    handleEditComment={handleEditComment}
                  />
                  <SubComments
                    removeComment={removeComment}
                    commentId={comment.id}
                    comments={array}
                    editCommentId={editCommentId}
                    handleEditComment={handleEditComment}
                    setEditCommentId={setEditCommentId}
                  />
                  <ReplayComment
                    comment={comment}
                    refresh={() => setRefresh(!refresh)}
                  />
                  <Divider className="mx-5" />
                </div>
              )
          )}
      </List>
      <div className="flex">
        <TextField
          onChange={(e) => setCommentText(e.target.value)}
          label="Leave a comment"
          placeholder="Type something here…"
          className="w-full"
          size="small"
        />
        <IconButton
          onClick={() => {
            handleAddComment();
            setCommentText(undefined);
          }}
          className="mt-[-1px] !bg-white rounded-none"
          disabled={!commentText}
          size="medium"
        >
          <SendIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
}
