import { DefaultItem, ID } from "@directus/sdk";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import update from "immutability-helper";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AppContext } from "../../context/app-context";
import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { AssetType, MetaphoreAssets } from "../../types/assets";
import { downloadInternalFile } from "../../utils";
import { Resolution } from "../card/card-box";
import CollectionModal from "../collection-modal/collection-modal";
import Comments from "../comments/comments";
import ConfirmDialog from "../dialogs/confirm";
import DownloadFile from "../dialogs/download-file";
import MediaTypeIcon from "../media-format/media-type-icon";
import UserRole from "../user-role/user-role";

import { StackItem } from "./stack-item";

type Props = {
  asset: DefaultItem<MetaphoreAssets>;
  fileType?: string;
  classes?: string;
  resolution?: Resolution;
  setCollectionItem?: boolean;
  collectionId?: number | undefined | string;
  stackVersions?: DefaultItem<MetaphoreAssets>[];
  setStackVersions?: React.Dispatch<
    React.SetStateAction<DefaultItem<MetaphoreAssets>[]>
  >;
};

export default function Dropdawn(props: Props): React.ReactElement {
  const {
    classes,
    setCollectionItem,
    collectionId,
    resolution,
    fileType,
    asset,
    stackVersions,
    setStackVersions,
  } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = React.useState(false);
  const [isOpenComments, setIsOpenComments] = React.useState(false);
  const [isDownloadSettings, setIsOpenDownloadSettings] = React.useState(false);
  const [openStackVersions, setOpenStackVersions] = React.useState(false);
  const { assets, selectedFiles, setAssets, sorting, filter } =
    React.useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { directus } = useDirectus();
  const { deleteStackVersionItem, getAssets } = Asset(directus);
  const isImageType =
    fileType === AssetType.webp ||
    fileType === AssetType.png ||
    fileType === AssetType.jpeg ||
    fileType === AssetType.jpg ||
    fileType === AssetType.tiff;

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleOpenStackVersions = (): void => {
    setOpenStackVersions(true);
  };

  const handleOpenComments = (): void => {
    setIsOpenComments(true);
    setAnchorEl(null);
  };

  const handleCloseStackVersions = (): void => {
    setOpenStackVersions(false);
    setAnchorEl(null);
  };

  const handleDeleteStackVersionItem = async (
    id: number | string
  ): Promise<void> => {
    if (confirm("Are you sure?")) {
      await deleteStackVersionItem(id);

      if (setStackVersions) {
        setStackVersions((prev) => {
          const stackItems = prev.filter((item) => item.id !== id);

          if (!stackItems.length) {
            setOpenStackVersions(false);
            setAnchorEl(null);
          }

          return stackItems;
        });
      }

      setAssets(await getAssets(sorting, filter));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id: string): void => {
    setAnchorEl(null);
  };

  const handleCloseDownloadSettings = (): void => {
    setIsOpenDownloadSettings(false);
  };

  const handleDelete = (): void => {
    (async () => {
      if (setCollectionItem) {
        const collections = await directus
          ?.items("metaphore_assets_metaphore_collections")
          .readMany(asset.collection_id as ID[]);

        if (collections?.data) {
          const updateCollectionIds = collections.data
            .filter(
              (item: DefaultItem<any>) =>
                item?.metaphore_collections_id != collectionId
            )
            .map((item: DefaultItem<any>) => item.id);

          await directus
            ?.items("metaphore_assets")
            .updateOne(asset.id, { collection_id: updateCollectionIds });
        }
      } else {
        await directus?.items("metaphore_assets").deleteOne(asset.id);
        await directus
          ?.items("metaphore_comments")
          .readByQuery({
            filter: {
              asset_id: asset.id,
            },
          })
          .then(async (res) => {
            const commentsIds = res.data?.map((c) => c.id);

            await directus
              ?.items("metaphore_comments")
              .deleteMany(commentsIds as ID[]);
          });

        setAnchorEl(null);
      }

      setAssets(() => assets?.filter((a) => a.id !== asset.id));
    })();
  };

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      !!setStackVersions &&
        setStackVersions((prevCards: DefaultItem<MetaphoreAssets>[]) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [
                hoverIndex,
                0,
                prevCards[dragIndex] as DefaultItem<MetaphoreAssets>,
              ],
            ],
          })
        );
    },
    []
  );

  const renderCard = React.useCallback(
    (card: DefaultItem<MetaphoreAssets>, index: number) => {
      return (
        <StackItem
          key={card.id}
          index={index}
          id={card.id}
          moveCard={moveCard}
          stackVersions={stackVersions}
        >
          <div
            key={card.id}
            className="flex justify-between gap-[10px] items-center pt-[10px] "
          >
            <div>{index + 2}</div>
            <DragIndicatorIcon className="cursor-move" />
            <MediaTypeIcon
              asset={card}
              classes="text-[50px] max-w-[50px] max-h-[50px] object-contain"
            />
            <div className="w-full truncate">
              <Tooltip title={card.name} followCursor>
                <span>{card.name}</span>
              </Tooltip>
            </div>
            <IconButton onClick={() => handleDeleteStackVersionItem(card.id)}>
              <LinkOffIcon />
            </IconButton>
          </div>
        </StackItem>
      );
    },
    [stackVersions]
  );

  return (
    <>
      <div className={classes}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: open ? "#DD2127" : "" }} />
        </IconButton>
        <Menu
          id="long-menu"
          className="shadow rounded-[15px]"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "auto",
              padding: 0,
              borderRadius: "15px",
            },
          }}
        >
          <MenuItem
            disabled
            sx={{ "&:hover": { color: "#DD2127" } }}
            onClick={() => handleClose(asset.id)}
          >
            <InfoOutlinedIcon />
          </MenuItem>
          <MenuItem
            disabled
            sx={{ "&:hover": { color: "#DD2127" } }}
            onClick={() => handleClose(asset.id)}
          >
            <FileUploadOutlinedIcon />
          </MenuItem>
          <MenuItem
            sx={{ "&:hover": { color: "#DD2127" } }}
            onClick={() => {
              setAnchorEl(null);

              if (isImageType) {
                setIsOpenDownloadSettings(true);

                return;
              }

              downloadInternalFile(directus, asset.image);
            }}
          >
            <FileDownloadOutlinedIcon />
          </MenuItem>
          <MenuItem
            disabled
            sx={{ "&:hover": { color: "#DD2127" } }}
            onClick={() => handleClose(asset.id)}
          >
            <LanOutlinedIcon />
          </MenuItem>
          <UserRole>
            <MenuItem
              sx={{ "&:hover": { color: "#DD2127" } }}
              onClick={() => setIsOpenConfirmDialog(true)}
            >
              <DeleteForeverOutlinedIcon />
            </MenuItem>
          </UserRole>
          <MenuItem
            onClick={handleOpenDialog}
            sx={{ "&:hover": { color: "#DD2127" } }}
          >
            <DashboardCustomizeIcon />
          </MenuItem>
          <MenuItem
            disabled={!stackVersions?.length}
            onClick={handleOpenStackVersions}
            sx={{ "&:hover": { color: "#DD2127" } }}
          >
            <LayersOutlinedIcon />
          </MenuItem>
          <MenuItem
            sx={{ "&:hover": { color: "#DD2127" } }}
            onClick={() => handleOpenComments()}
          >
            <ChatOutlinedIcon />
          </MenuItem>
        </Menu>
      </div>
      <Comments
        isOpen={isOpenComments}
        assetId={asset.id}
        closeDialog={() => setIsOpenComments(false)}
      />
      <CollectionModal
        asset={asset}
        openModal={openDialog}
        closeModal={setOpenDialog}
        setAnchorEl={setAnchorEl}
      />
      {fileType && resolution && (
        <DownloadFile
          resolution={resolution}
          asset={asset}
          fileType={fileType}
          isOpen={isDownloadSettings}
          onClose={handleCloseDownloadSettings}
        />
      )}
      <ConfirmDialog
        header="Delete from Collection"
        text="The Asset(s) will be removed from this collection only. The Asset(s) will still be visible in other collections and in the File-System."
        isOpen={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        onConfirm={handleDelete}
      />
      {stackVersions && (
        <Dialog
          onClose={handleCloseStackVersions}
          aria-labelledby="customized-dialog-title"
          open={openStackVersions}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Manage version stack
            <div className="text-[13px] leading-none text-slate-400">
              {stackVersions.length + 1} version
            </div>
            <IconButton
              aria-label="close"
              onClick={handleCloseStackVersions}
              sx={{
                position: "absolute",
                right: 8,
                top: 18,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <div className="flex justify-between gap-[10px] items-center">
              <div>1</div>
              <img
                className="max-w-[50px] max-h-[50px]"
                src={`${getDirectusAssetsPath(directus)}/${
                  asset.image
                }?height=50&width=50`}
                alt={asset.name}
              />
              <div className="w-full truncate">
                <Tooltip title={asset.name} followCursor>
                  <span>{asset.name}</span>
                </Tooltip>
              </div>
            </div>
            <DndProvider backend={HTML5Backend}>
              {stackVersions?.length &&
                stackVersions.map((card, i) => renderCard(card, i))}
            </DndProvider>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
