import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import Header from "./header";
import MainContainer from "./main-container";
import MainSidebar from './main-sidebar';

export default function Layout(): ReactElement {
    return (
        <div className='flex min-h-screen min-w-screen bg-companyLightGrey overflow-hidden'>
            <MainSidebar />
            <MainContainer>
                <Header />
                <Outlet />
            </MainContainer>
        </div>     
    );
}