import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import React, { ReactElement, ReactNode } from 'react';

import variables from '../variables.json';

export const defaultTheme = createTheme({
    typography: {
        fontFamily: 'BrownStd',
    },
    palette: {
        primary: {
            main: variables.colors.company.primary,
        },
        secondary: {
            main: variables.colors.company.darkGrey,
        },
    },
});

type Props = {
    children: ReactNode;
};

export default function MuiThemeProvider(props: Props): ReactElement {
    const { children } = props;
    return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
}
