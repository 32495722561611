import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useContext } from "react";

import { AppContext } from "../../../../context/app-context";
import Collection from "../../../../services/collection";
import { MetaphoreCollections } from "../../../../types/collections";

type Props = {
  name: string;
  collections: MetaphoreCollections[] | null | undefined;
  sharedCollections?: MetaphoreCollections[] | null | undefined;
};

export default function LeftNavigation(props: Props): React.ReactElement {
  const { name, collections, sharedCollections } = props;
  const {
    setCollectionId,
    setCollections,
    setFilterCollections,
    collectionId,
    filterCollections,
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { deleteCollectionById } = Collection();

  const handleCollectionChange = (id: string | number): void => {
    collectionId === id ? setCollectionId(undefined) : setCollectionId(id);
  };

  const handleDeleteCollection = (collection: MetaphoreCollections): void => {
    if (confirm("Are you sure?")) {
      (async () => {
        await deleteCollectionById(collection.id);

        enqueueSnackbar(`Collection ${collection.name} was deleted.`, {
          variant: "success",
        });

        setFilterCollections(() =>
          filterCollections?.filter((item) => item.id !== collection.id)
        );
        setCollections(() =>
          collections?.filter((item) => item.id !== collection.id)
        );
        setCollectionId(undefined);
      })();
    }
  };

  return (
    <List
      sx={{ bgcolor: "background.paper" }}
      className="shadow max-w-[320px] width-full rounded-[15px] overflow-hidden pt-[8px] mb-[25px] ml-[45px]"
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {name}
        </ListSubheader>
      }
    >
      <Accordion className="accordion">
        <AccordionSummary
          className="m-0"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListItemText primary={`Your Collections (${collections?.length})`} />
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <List component="div" disablePadding>
            {collections &&
              collections.map((item) => {
                return (
                  <ListItemButton
                    selected={item.id === collectionId}
                    className="border-t-1 border-solid border-slate-300 pl-[30px]"
                    key={item.id}
                    sx={{ pl: 6 }}
                  >
                    <ListItemText
                      onClick={() => handleCollectionChange(item.id)}
                      primary={item.name}
                    />
                    {/* <IconButton onClick={() => handleDeleteCollection(item)}>
                                        <DeleteOutlineIcon />
                                    </IconButton> */}
                  </ListItemButton>
                );
              })}
          </List>
        </AccordionDetails>
      </Accordion>
      {sharedCollections && (
        <Accordion className="accordion">
          <AccordionSummary
            className="m-0"
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <ListItemText
              primary={`Collections shared with you (${sharedCollections?.length})`}
            />
          </AccordionSummary>
          <AccordionDetails className="p-0">
            <List component="div" disablePadding>
              {sharedCollections.map((item) => {
                return (
                  <ListItemButton
                    selected={item.id === collectionId}
                    className="border-t-1 border-solid border-slate-300 pl-[30px]"
                    onClick={() => handleCollectionChange(item.id)}
                    key={item.id}
                    sx={{ pl: 6 }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </List>
  );
}
