import { DefaultItem, ID } from "@directus/sdk";
import { enqueueSnackbar } from "notistack";
import { useContext, useState } from "react";

import { AppContext } from "../../../context/app-context";
import { useDirectus } from "../../../providers/DirectusProvider";
import Collection from "../../../services/collection";
import { MetaphoreCollections } from "../../../types/collections";

interface ReturnProps {
    inputElement: string;
    collectionsIds: any[];
    collections: MetaphoreCollections[] | undefined;
    setInputElement: React.Dispatch<React.SetStateAction<string>>;
    setCollectionsIds: React.Dispatch<React.SetStateAction<any[]>>;
    handleCreateCollection: () => Promise<void>;
    handleAddToCollections: (
        id: string, 
        closeModal: React.Dispatch<React.SetStateAction<boolean>>, 
        setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>> | undefined,
        selectedAssets: string[] | undefined,
    ) => Promise<void>;
}

export const useCollectionModal = (): ReturnProps => {
    const [inputElement, setInputElement] = useState<string>('');
    const [collectionsIds, setCollectionsIds] = useState<any[]>([]);
    const { collections, sorting, user, setFilterCollections, setCollections, setSelectedFiles } = useContext(AppContext);
    const { createCollection, getCollections } = Collection();
    const { directus } = useDirectus();

    const handleAddToCollections = async (
        id: string, 
        closeModal: React.Dispatch<React.SetStateAction<boolean>>,
        setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>> | undefined,
        selectedAssets: string[] | undefined,
    ): Promise<void> => {
        if (selectedAssets?.length) {
            const currectCollections = await directus?.items('metaphore_assets_metaphore_collections').readMany(selectedAssets as ID[]);
            const mergeCollections: any[] = [];
            
            currectCollections?.data?.map((item: DefaultItem<any>) => {
                mergeCollections.push({ metaphore_collections_id: item.metaphore_collections_id });
            });
            
            await directus?.items('metaphore_assets').updateMany(
                selectedAssets, 
                { 
                    collection_id: [...collectionsIds, ...mergeCollections]
                }
            );
        } else {
            await directus?.items('metaphore_assets').updateOne(
                id, 
                { 
                    collection_id: collectionsIds
                }
            );
        }
        
        closeModal(false);
        setSelectedFiles([]);
        enqueueSnackbar('File was added to collections.', { variant: 'success' });

        if (setAnchorEl) {
            setAnchorEl(null);
        }
    };

    const handleCreateCollection = async (): Promise<void> => {
        const user = await directus?.users.me.read();
    
        if (user?.id) {
            await createCollection(inputElement, user.id);
            
            setFilterCollections(await getCollections(sorting) ?? undefined);
            setCollections(await getCollections(["name"]) ?? undefined);
        }
    
        setInputElement('');
    };

    return {
        collectionsIds,
        inputElement,
        collections,
        setInputElement,
        setCollectionsIds,
        handleAddToCollections,
        handleCreateCollection
    };
}