import { DefaultItem } from '@directus/sdk';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { format } from "date-fns";
import * as React from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../context/app-context';
import { useDirectus } from '../../providers/DirectusProvider';
import Asset from '../../services/asset';
import { MetaphoreAssets } from '../../types/assets';
import { MetaphoreBrands } from '../../types/brands';
import { findRootCategoryById } from '../../utils';
import Dropdawn from '../dropdawn/dropdawn';
import MediaTypeIcon from '../media-format/media-type-icon';

type Props = {
    classes?: string;
    draggable?: boolean;
    isCheckbox? : boolean;
    asset: DefaultItem<MetaphoreAssets>;
    offset: number;
    isCollection?: boolean;
    getDradId?: number | undefined | string;
    setDragId?: React.Dispatch<React.SetStateAction<number | undefined | string>>;
};

export default function ListItem(props: Props): React.ReactElement {
    const { classes, asset, offset, draggable, isCheckbox, isCollection, getDradId, setDragId } = props;

    const { category, setSelectedFiles, selectedFiles, assets, setAssets } = React.useContext(AppContext);
    const [stackVersions, setStackVersions] = React.useState<DefaultItem<MetaphoreAssets>[]>([]);
    const [type, setType] = React.useState<string | undefined>();
    const [brand, setBrand] = React.useState<MetaphoreBrands[]>([]);
    const { directus } = useDirectus();
    const { setFavorite, getFileType, addAssetToStackVersion, getAssetStackVersions } = Asset(directus);

    React.useEffect(() => {
        (async () => {
            const brands = await directus?.items('metaphore_brands').readByQuery();
            const file = await getFileType(asset.image);

            if(brands?.data) {
                setBrand(brands.data);
            }

            setType(file?.type);
            setStackVersions(await getAssetStackVersions(asset.id) ?? []);
        })();
    }, []);

    const capitalizeFirstLetter = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, checked } = event.target;

        setSelectedFiles([...selectedFiles, id]);

        if (!checked) {
            setSelectedFiles(selectedFiles.filter(item => item !== id));
        }
    };

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
        if (setDragId) {
            setDragId(parseInt(event.currentTarget.id));
        }
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
        event.currentTarget.style.background = '';
    };

    const handleDragEnd = (event: React.DragEvent<HTMLDivElement>): void => {
        event.currentTarget.style.background = '';
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        
        if (parseInt(event.currentTarget.id) != getDradId) {
            event.currentTarget.style.background = '#d3d3d366';
        }
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>, asset: DefaultItem<MetaphoreAssets>): Promise<void> => {
        event.preventDefault();
        event.currentTarget.style.background = '';

        if (getDradId && getDradId !== asset.id) {
            await addAssetToStackVersion(asset.id, getDradId);
            setStackVersions(await getAssetStackVersions(asset.id) ?? []);
            setAssets(assets?.filter(file => file.id !== getDradId));
        }
    };

    return (
        <div 
            id={asset.id}
            draggable={!stackVersions.length ? draggable : false} 
            onDragStart={(e) => handleDragStart(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDragEnd={(e) => handleDragEnd(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, asset)}
            key={asset.id} 
            style={{borderColor: '#E8E8E8'}} 
            className={`
                ${!stackVersions.length ? 'cursor-grabbing' : ''}
                flex shadow-none rounded-[15px] border-solid border-1 w-full p-[10px] text-[14px] items-center mb-20
            `}
        >
            <div className='flex-1'>
                <div className='flex items-center'>
                    <div className='flex w-[40px] flex-col'>
                        <Checkbox 
                            key={asset.id} 
                            id={asset.id.toString()} 
                            onChange={handleSelectFile} 
                            value={asset.id}  
                            checked={selectedFiles.includes(asset.id.toString())}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} 
                        />
                        <Checkbox 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFavorite(asset.id, event.target.checked)}
                            defaultChecked={asset.favorite} 
                            icon={<FavoriteBorder />} 
                            checkedIcon={<Favorite />} 
                        />
                    </div>
                    {!!stackVersions.length && (
                        <span className='font-bold'>v. {stackVersions.length + 1}</span>
                    )}
                    <Link to={`/file/${asset.id}/${offset}`}>
                        <MediaTypeIcon asset={asset} classes={`text-[50px] max-w-[50px] max-h-[50px] object-contain ${stackVersions.length ? 'ml-[20px]' : 'ml-[42px]'}`} />
                    </Link>
                </div>
            </div>
            <div className='flex-1 pr-10'>{asset.name}</div>
            {/* <div className='flex-1'>{brand && (brand?.find(item => item.id === asset.brand_id))?.name}</div> */}
            <div className='flex-1'>{findRootCategoryById(asset?.category_id, category)}</div>
            <div className='flex-1'>{capitalizeFirstLetter(asset.asset_types.replace("_", " "))}</div>
            <div className='flex-1'>
                <div className='flex justify-between items-center'>
                    {asset?.date_created && (format(new Date(asset?.date_updated || asset?.date_created), 'dd-MM-yyyy'))}
                    <Dropdawn 
                        asset={asset}
                        stackVersions={stackVersions} 
                        setStackVersions={setStackVersions}  
                        setCollectionItem={isCollection} 
                    />
                </div>
            </div>
        </div>
    )
}