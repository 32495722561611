import { DefaultItem } from '@directus/sdk';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import React from 'react';

import { useDirectus } from '../../../providers/DirectusProvider';
import Asset from '../../../services/asset';
import Collection from '../../../services/collection';
import { MetaphoreAssets } from '../../../types/assets';

type Props = {
    collectionId: string;
    filter: object;
    sorting: string[];
    setAssets: React.Dispatch<React.SetStateAction<DefaultItem<MetaphoreAssets>[] | undefined | null>>;
};

export default function CollectionSearch(props: Props): React.ReactElement {
    const { sorting, filter, collectionId, setAssets } = props;
    const { directus } = useDirectus();
    const { getCollectionById, getCollectionItemsById } = Collection();
    const { getAssets } = Asset(directus);

    const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        setAssets(await getCollectionItemsById(collectionId, {
            name: {
                _icontains: `%${event.target.value}%`
            }
        }));
    };

    return (
        <div className='relative w-full'>
            <label className="absolute text-[12px] top-[7px] left-[15px] text-slate-500 z-10">
                Search in collection
            </label>
            <TextField
                className='shadow border-0 rounded-[15px] bg-white w-full'
                placeholder="Enter keyword"
                onChange={handleSearch}
                sx={{ 
                    '& .MuiInputBase-input': { paddingTop: '24px', paddingBottom: '9px' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: '15px' }
                }}
                InputProps={{
                    endAdornment: <SearchIcon className='text-[22px] text-slate-400 cursor-pointer' />,
                }}
            />
        </div>
    );
}
