import { Button, DialogActions, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

export interface ConfirmDialogProps {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  text: string;
  header: string;
}

export default function ConfirmDialog(
  props: ConfirmDialogProps
): React.ReactElement {
  const { onClose, onConfirm, isOpen, text, header } = props;

  return (
    <Dialog maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle>{header}</DialogTitle>
      <DialogContent className="flex max-h-[500px] max-w-[500px] min-w-[300px]">
        {text}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
