import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { ReactElement } from 'react';
import { useCookies } from 'react-cookie';

export default function LayoutSwitcher(): ReactElement {
    const [cookies, setCookie] = useCookies<string>(['listLayout']);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        type: string
    ): void => {
        if (!type) return;

        setCookie('listLayout', type);
    };

    const control = {
        value: cookies.listLayout ?? '1',
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <Stack alignItems="center">
            <ToggleButtonGroup 
                {...control}
                className="shadow rounded-[15px] bg-white overflow-hidden h-full"  
                size="large"
                sx={{
                    '& > .Mui-selected': {
                        color: '#DD2127!important',
                        background: 'white!important'
                    }
                }} 
            >
                <ToggleButton className="border-none" value="1" key="grid">
                    <WindowIcon  className='text-[22px]' />
                </ToggleButton>
                <ToggleButton style={{borderLeft: '1px solid #999999'}} className="border-none" value="2" key="list">
                    <FormatListBulletedIcon className='text-[22px]' />
                </ToggleButton>
            </ToggleButtonGroup>
       </Stack>
    );
}
