import FindInPageIcon from '@mui/icons-material/FindInPage';
import React, { ReactElement } from "react";

export default function NotFound(): ReactElement {
    return (
        <div className="flex w-full flex-row gap-[25px] h-full">
            <div className="flex flex-col items-center w-full justify-center">
                <FindInPageIcon sx={{color: '#DD2127', fontSize: '240px'}} />
                <h2 className='text-[50px]'>Page not found</h2>
            </div>
        </div>
    );
}