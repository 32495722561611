
import { DefaultItem, FileItem, IDirectus } from "@directus/sdk";

import { CustomCollections } from "../types";
import { Approvals } from "../types/approvals";
import { MetaphoreAssets } from "../types/assets";
import { Status } from "../types/enums/status";

interface Asset {
    getFileType: (id: string) => Promise<DefaultItem<FileItem<unknown>> | undefined>;
    getAssetById: (id: string) => Promise<DefaultItem<MetaphoreAssets> | undefined | null>;
    updateAssetById: (id: string, options: object) => Promise<void>;
    getAssets: (sort?: any, filter?: any, limit?: number) => Promise<DefaultItem<MetaphoreAssets>[] | undefined | null>;
    getDraftAssets: (patientId: string, sort?: any, filter?: any) => Promise<DefaultItem<MetaphoreAssets>[] | undefined>;
    getSessions: (sort?: any, filter?: any) => Promise<any>;
    getApprovalsSessions: () => Promise<Approvals[] | undefined>;
    setFavorite: (id: number | string, favorite: boolean) => Promise<void>;
    getAssetStackVersions: (id: number | string) => Promise<DefaultItem<MetaphoreAssets>[] | undefined | null>;
    deleteStackVersionItem: (id: number | string) => Promise<void>;
    addAssetToStackVersion: (id: number | string, dragId: number | string) => Promise<void>;
    renameAssetById: (id: number | string, name: string) => Promise<void>;
    changeCategoryById: (id: number | string, catId: number) => Promise<void>;
}

const Asset = (directus: IDirectus<CustomCollections> | undefined ): Asset => {
    const setFavorite = async (id: number | string, favorite: boolean): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(
                id,
                {
                    favorite: favorite
                }
            );
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getAssetStackVersions = async (id: number | string): Promise<DefaultItem<MetaphoreAssets>[] | undefined | null> => {
        try {
            const response = await directus?.items('metaphore_assets').readByQuery(
                {
                    filter: {
                        parent_id: id
                    },
                    sort: ['ordering_index'],
                }
            );

            return response?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const deleteStackVersionItem = async (id: number | string): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(id,
                {
                    parent_id: null
                }
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const renameAssetById = async (id: number | string, name: string): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(id,
                {
                    name: name
                }
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const changeCategoryById = async (id: number | string, catId: number): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(id,
                {
                    category_id: catId
                }
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const addAssetToStackVersion = async (id: number | string, dragId: number | string): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(dragId,
                {
                    parent_id: id as number
                }
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getAssets = async (sort?: any[], filter?: any, limit?: number, offset?: number): Promise<DefaultItem<MetaphoreAssets>[] | undefined | null> => {
        try {
            const response = await directus?.items('metaphore_assets').readByQuery({
                filter: {
                    ...filter,
                    parent_id: {
                        _null: true
                    }
                },
                limit: limit,
                offset: offset,
                sort: sort,
                meta: "*",
                fields: ['*', 'collection_id.*']
            });

            return response?.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const updateAssetById = async (id: string, options: object): Promise<void> => {
        try {
            await directus?.items('metaphore_assets').updateOne(id, options);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getSessions = async (sort?: any, filter?: any): Promise<any> => {
        const sessions: any[] = [];

        try {
            await directus?.items('metaphore_approval_session').readByQuery().then(async (data) => {
                if (data?.data?.length) {
                    await Promise.all(data.data.map(async (item: any) => {
                        const items = await directus?.items('metaphore_assets').readByQuery({
                            filter: {
                                id: {
                                    _in: JSON.parse(item.assets_ids)
                                },
                                ...filter,
                            },
                            sort: sort ?? ['-date_created'],
                        });

                        sessions.push({
                            ...item,
                            items: items.data
                        });
                    }));
                }
            });

            // eslint-disable-next-line no-console
            // console.log(sessions)

            return sessions || [];
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getDraftAssets = async (patientId: string, sort?: any, filter?: any): Promise<DefaultItem<MetaphoreAssets>[] | undefined> => {
        try {
            const response = await directus?.items('metaphore_assets').readByQuery({
                filter: {
                    ...filter,
                    status: Status.Draft,
                    user_created: patientId,
                },
                sort: sort ?? ['-date_created'],
                meta: "*",
                fields: ['*']
            });
            // eslint-disable-next-line no-console
            // console.log(response);
            return response?.data || [];
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getApprovalsSessions = async (): Promise<Approvals[] | undefined> => {
        const sessions: any[] = [];

        try {
            await directus?.items('metaphore_assets').readByQuery({
                filter: {
                    status: Status.Draft,
                },
                groupBy: ['user_created'],
                aggregate: {
                    count: '*'
                }
            }).then(async (data) => {
                if (data?.data?.length) {
                    await Promise.all(data.data.map(async (item) => {
                        const user = await directus?.users.readOne(item.user_created);

                        sessions.push({
                            ...item,
                            first_name: user?.first_name,
                            last_name: user?.last_name,
                            email: user?.email,
                        });
                    }));
                }
            });

            return sessions || [];
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getFileType = async (id: string): Promise<DefaultItem<FileItem<unknown>> | undefined> => {
        if (!id) return;
    
        try {
            const file = await directus?.files.readOne(id);
            return file || undefined;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    const getAssetById = async (id: string): Promise<DefaultItem<MetaphoreAssets> | undefined | null> => {
        try {
            return await directus?.items('metaphore_assets').readOne(id);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    return {
        getFileType,
        getAssetById,
        updateAssetById,
        getAssets,
        getDraftAssets,
        getSessions,
        getApprovalsSessions,
        setFavorite,
        getAssetStackVersions,
        deleteStackVersionItem,
        addAssetToStackVersion,
        renameAssetById,
        changeCategoryById
    };
};

export default Asset;
