import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import GroupIcon from "@mui/icons-material/Group";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import * as React from "react";
import { ReactElement, useContext, useEffect, useState } from "react";

import ShareLink from "../../../../components/dialogs/share-link";
import UserRole from "../../../../components/user-role/user-role";
import { AppContext } from "../../../../context/app-context";
import { useDirectus } from "../../../../providers/DirectusProvider";
import Collection from "../../../../services/collection";
import User from "../../../../services/user";
import { MetaphoreCollections } from "../../../../types/collections";

type Props = {
  imageId: string;
  collection: MetaphoreCollections;
  classes?: string;
};

export default function ContextMenu(props: Props): ReactElement {
  const { classes, collection, imageId } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState<[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [openSharedDialig, setOpenSharedDialog] = useState(false);
  const [shareLinkDialog, setShareLinkDialog] = useState(false);
  const [inputElement, setInputElement] = useState<string>(collection.name);
  const {
    deleteCollectionById,
    renameCollectionById,
    getCollections,
    getSharedCollections,
    setShareCollection,
  } = Collection();
  const {
    setFilterCollections,
    setCollections,
    setSharedCollections,
    filterCollections,
    collections,
    sorting,
    user,
    setUser,
    setCollectionId,
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { directus } = useDirectus();

  const open = Boolean(anchorEl);

  useEffect(() => {
    (async () => {
      if (collection.shared_user_ids.length) {
        const sharedUsers = await directus
          ?.items("metaphore_collections_directus_users")
          .readMany(collection.shared_user_ids);

        setSelectedUsers(sharedUsers?.data ?? []);
      }

      setUser(await directus?.users.me.read());
      setUsers(await User(directus).getUsers());
    })();
  }, []);

  const findUserID = (key: string, arr: any[]): boolean => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].directus_users_id === key) {
        return true;
      }
    }

    return false;
  };

  const handleClickContextMenu = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = (id: string): void => {
    setAnchorEl(null);
  };

  const handleOpenRenameDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseRenameDialog = (): void => {
    setOpenDialog(false);
  };

  const handleOpenSharedDialog = (): void => {
    setOpenSharedDialog(true);
  };

  const handleOpenSharedLink = (): void => {
    setShareLinkDialog(true);
  };

  const handleCloseSharedDialog = (): void => {
    setOpenSharedDialog(false);
  };

  const handleRenameCollection = async (): Promise<void> => {
    setOpenDialog(false);
    setAnchorEl(null);

    await renameCollectionById(collection.id, inputElement);

    enqueueSnackbar(`Collection ${collection.name} was renamed.`, {
      variant: "success",
    });

    setFilterCollections((await getCollections(sorting)) ?? undefined);
    setSharedCollections((await getSharedCollections(sorting)) ?? undefined);
    setCollections((await getCollections(["name"])) ?? undefined);
  };

  const handleShareCollection = async (): Promise<void> => {
    setOpenSharedDialog(false);
    setAnchorEl(null);

    await setShareCollection(collection.id, selectedUsers);

    enqueueSnackbar(`Collection ${collection.name} was shared.`, {
      variant: "success",
    });

    setSharedCollections((await getSharedCollections(sorting)) ?? undefined);
  };

  const handleDeleteCollection = (): void => {
    if (confirm("Are you sure?")) {
      (async () => {
        await deleteCollectionById(collection.id);

        enqueueSnackbar(`Collection ${collection.name} was deleted.`, {
          variant: "success",
        });

        setAnchorEl(null);
        setFilterCollections(() =>
          filterCollections?.filter((item) => item.id !== collection.id)
        );
        setCollections(() =>
          collections?.filter((item) => item.id !== collection.id)
        );
        setCollectionId(undefined);
      })();
    }
  };

  const handleChangeSharingUser = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.checked) {
      setSelectedUsers((prev) => [...prev, { directus_users_id: e.target.id }]);
    } else {
      setSelectedUsers((prev) =>
        prev.filter((item) => item.directus_users_id != e.target.id)
      );
    }
  };

  return (
    <div className={classes}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickContextMenu}
      >
        <MoreVertIcon style={{ color: open ? "#DD2127" : "" }} />
      </IconButton>
      <Menu
        id="long-menu"
        className="shadow rounded-[15px]"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseContextMenu}
        PaperProps={{
          style: {
            width: "auto",
            padding: 0,
            borderRadius: "15px",
          },
        }}
      >
        <MenuItem
          onClick={handleOpenSharedDialog}
          sx={{ "&:hover": { color: "#999999" } }}
        >
          <Tooltip title="Share" placement="right">
            <GroupIcon />
          </Tooltip>
        </MenuItem>
        <MenuItem
          onClick={handleOpenSharedLink}
          sx={{ "&:hover": { color: "#999999" } }}
        >
          <Tooltip title="Copy share link" placement="right">
            <ShareOutlinedIcon />
          </Tooltip>
        </MenuItem>
        <MenuItem
          onClick={handleOpenRenameDialog}
          sx={{ "&:hover": { color: "#999999" } }}
        >
          <Tooltip title="Rename" placement="right">
            <ModeEditOutlineOutlinedIcon />
          </Tooltip>
        </MenuItem>
        <MenuItem disabled sx={{ "&:hover": { color: "#999999" } }}>
          <PhotoOutlinedIcon />
        </MenuItem>
        <UserRole>
          <MenuItem
            onClick={handleDeleteCollection}
            sx={{ "&:hover": { color: "#999999" } }}
          >
            <Tooltip title="Delete" placement="right">
              <DeleteForeverOutlinedIcon />
            </Tooltip>
          </MenuItem>
        </UserRole>
      </Menu>
      <Dialog open={openDialog} onClose={handleCloseRenameDialog}>
        <DialogTitle>Collection name</DialogTitle>
        <DialogContent className="min-w-[300px]">
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputElement(e.target.value)
            }
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={collection.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRenameDialog}>Cancel</Button>
          <Button
            disabled={!inputElement.replace(/\s/g, "").length}
            onClick={handleRenameCollection}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSharedDialig} onClose={handleCloseSharedDialog}>
        <DialogTitle>Share collection with users:</DialogTitle>
        <DialogContent className="min-w-[300px]">
          <FormGroup>
            {users.map((usr: any) => {
              if (usr.email && usr.id !== user?.id) {
                return (
                  <FormControlLabel
                    disabled={collection.user_id == usr.id}
                    sx={{
                      svg: { fontSize: "20px" },
                      span: { fontSize: "14px" },
                    }}
                    key={usr.id}
                    control={
                      <Checkbox
                        onChange={handleChangeSharingUser}
                        checked={
                          collection.user_id == usr.id ||
                          !!findUserID(usr.id, selectedUsers)
                        }
                        id={usr.id}
                      />
                    }
                    label={`${usr.email} ${
                      collection.user_id == usr.id ? "(Owner)" : ""
                    }`}
                  />
                );
              }
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSharedDialog}>Cancel</Button>
          <Button onClick={handleShareCollection}>Share</Button>
        </DialogActions>
      </Dialog>
      <ShareLink
        isOpen={shareLinkDialog}
        setToggle={setShareLinkDialog}
        collectionId={collection.id.toString()}
      />
    </div>
  );
}
