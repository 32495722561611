import { RelationItem } from "@directus/sdk";

import { MetaphoreCollections } from "./collections";

export type MetaphoreAssets = {
	id: string;
    user_created: string;
	status: string;
	approval: string;
	sort?: string;
	asset_type: string;
	asset_types: string;
	parent_id?: number | null;
	ordering_index?: number | null;
	category_id?: number;
	collection_id?: (string | number)[] | RelationItem<MetaphoreCollections>[any] | null;
	// collection_id?: (string | number | any)[] | null;
	next_asset_id?: number;
	prev_asset_id?: number;
	language_regions_id?: number;
	brand_id?: number;
	content_amount?: string;
	unit?: string;
	name?: string;
	geometry?: string;
	metashots_type?: string;
	type?: string;
	favorite?: boolean;
    image: string;
    date_updated?: string;
    date_created?: string;
};

export enum AssetType {
    png = "image/png",
    jpeg = "image/jpeg",
    jpg = "image/jpg",
    tiff = "image/tiff",
    webp = "image/webp",
    pdf = "application/pdf",
    psd = "image/vnd.adobe.photoshop",
    mp4 = "video/mp4",
};