import { CssBaseline, StyledEngineProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import './global.css';
import { DirectusProvider } from './providers/DirectusProvider';
import MuiThemeProvider from './providers/MuiThemeProvider';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL ?? ""}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider>
          <CssBaseline />
          <DirectusProvider apiUrl={process.env.REACT_APP_API_URL ?? ""}>
            <App />
          </DirectusProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
