import { DefaultItem } from "@directus/sdk";
import { useEffect, useState } from "react";

import { useDirectus } from "../../../providers/DirectusProvider";
import Asset from "../../../services/asset";
import Collection from "../../../services/collection";
import { MetaphoreAssets } from "../../../types/assets";
import { MetaphoreCollections } from "../../../types/collections";

interface Props {
    count: number;
    imageId: string;
    asset: DefaultItem<MetaphoreAssets> | undefined;
    // setCount: React.Dispatch<React.SetStateAction<number>>;
}

export const useCollection = (collection: MetaphoreCollections): Props => {
    const [count, setCount] = useState<number>(0);
    const [imageId, setImageId] = useState<string>('');
    const [asset, setAsset] = useState<DefaultItem<MetaphoreAssets>>();
    const { getCollectionItemsById } = Collection();
    const { directus } = useDirectus();
    const { getAssetById } = Asset(directus);

    useEffect(() => {
        (async () => {
            const collectionAssets = await getCollectionItemsById(collection.id);

            if (collectionAssets?.length) {
                const firstAssetImage = await getAssetById(collectionAssets[0]?.id);
                setAsset(firstAssetImage ?? undefined);
                setImageId(firstAssetImage?.image ?? '');
            }
            
            setCount(collectionAssets?.length ?? 0);
        })();
    }, []);

    return {
        count,
        imageId,
        asset
    };
}