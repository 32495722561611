import { SnackbarProvider } from 'notistack';
import { ReactElement } from 'react';
import { Route, Routes } from "react-router-dom";

import { AppContext } from "./context/app-context";
import { useApp } from './hooks/useApp';
import Layout from './layout/layout';
import { Account, Collections, DetailPage, Files, Home, Login, NotFound, Settings } from './pages';
import ApprovalsAssets from './pages/approvals/approvals-assets';
import ApprovalsGroup from './pages/approvals/approvals-group';
import RequestPassword from './pages/password/request-password';
import ResetPassword from './pages/password/reset-password';
import PublicUser from './pages/public-user/public-user';

function App(): ReactElement {
  const { 
    sessions,
    token, 
    role, 
    user,
    lastAccess, 
    category, 
    assets, 
    selectedFiles,
    selectedFilesAll,
    filter,
    sorting,
    filterCollections,
    collections,
    sharedCollections,
    collectionId,
    countItems,
    setSessions,
    setToken, 
    setRole, 
    setUser, 
    setCategory, 
    setAssets,
    setSelectedFiles,
    setSelectedFilesAll,
    setFilter,
    setSorting,
    setFilterCollections,
    setCollections,
    setSharedCollections,
    setCollectionId,
    setCountItems
  } = useApp();

  if (token === undefined) {
    return <div />;
  }

  if (!token) {
    return (
      <Routes>
        <Route index element={<Login setToken={setToken} />} />
        <Route path="request-password" element={<RequestPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="collection/:id" element={<PublicUser />} />
        <Route path="*" element={<Login setToken={setToken} />} />
      </Routes>
    );
  }

  return (
    <AppContext.Provider value={{ 
      sessions,
      token, 
      role, 
      user, 
      lastAccess, 
      assets, 
      category, 
      selectedFiles, 
      selectedFilesAll, 
      filter,
      sorting,
      filterCollections,
      collections,
      sharedCollections,
      collectionId,
      countItems,
      setSessions,
      setToken, 
      setRole, 
      setUser, 
      setCategory, 
      setAssets, 
      setSelectedFiles, 
      setSelectedFilesAll,
      setFilter,
      setSorting,
      setFilterCollections,
      setCollections,
      setSharedCollections,
      setCollectionId,
      setCountItems
    }}>
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="files" element={<Files />} />
            <Route path="file/:id/:offset" element={<DetailPage />} />
            <Route path="collections" element={<Collections />} />
            <Route path="collection/:id" element={<Collections />} />
            <Route path="approval/:id" element={<ApprovalsAssets />} />
            <Route path="approvals" element={<ApprovalsGroup />} />
            <Route path="settings" element={<Settings />} />
            <Route path="account" element={<Account />} />
            <Route path="*" element={<NotFound />} />
            <Route path="not-found" element={<NotFound />} />
          </Route>
        </Routes>
      </SnackbarProvider>
    </AppContext.Provider>
  );
}

export default App;
