type Props = {
    children: React.ReactNode;
};

export default function List(props: Props): React.ReactElement {
    const { children } = props;

    return (
        <div className='w-full'>
            <section>
                <header className='flex mb-[25px] px-10'>
                    <div className='flex-1 pl-10' />
                    <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Name</div>
                    {/* <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Brand</div> */}
                    <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Category</div>
                    <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Assets type</div>
                    <div className='flex-1 border-[#e0e0e0] border-solid border-l-1 border-indigo-600 pl-10 text-[14px] font-bold'>Last modified</div>
                </header>
                {children}
            </section>
        </div>
    );
}