import { Alert, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import MetaphoreLogo from '../../assets/media/metaphore-logo.png';
import { useDirectus } from '../../providers/DirectusProvider';

type Props = {
    setToken: React.Dispatch<React.SetStateAction<string | undefined | null>>
}

export default function Login(props: Props): React.ReactElement {
    const { setToken } = props;
    const [error, setError] = React.useState<boolean>(false);
    const { directus } = useDirectus();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        try {
            const login = await directus?.auth.login({
                email: data.get('email') as string,
                password: data.get('password') as string,
            });

            setToken(login?.access_token);
        } catch (e) {
            setError(true);
        }

        // TODO: Fix token state normally
        location.reload();
    };

    return (
        <div style={{ minHeight: 'inherit', background: '#f6f6f6' }}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img className='max-w-full mb-[120px] mt-[80px] mb-[40px] max-w-[300px]' src={MetaphoreLogo} alt='Company Logo' />
                    <Box 
                        sx={{ boxShadow: '0px 10px 15px #ccc', borderColor: '#dc2227', padding: '20px 40px 30px 40px' }}
                        className='bg-white border-t-4 w-[500px]' 
                        component="form" 
                        onSubmit={handleSubmit} 
                        noValidate 
                    >
                        <Typography 
                            className="mb-[35px] mt-10 font-['Open_Sans']"
                            component="h1" 
                            variant="h5"
                        >
                            Sign in to your account
                        </Typography>
                        {error && (<Alert className='w-full mb-20' severity="error">Incorrect email or password.</Alert>)}
                        <InputLabel className='text-[12px]'>Email Address</InputLabel>
                        <TextField
                            className='m-0 mb-[15px] bg-[#f6f6f6]'
                            required
                            fullWidth
                            name="email"
                            autoComplete="email"
                            autoFocus
                            variant="standard"
                            sx={{ '& .MuiInputBase-input': { padding: '7px 5px' } }}
                        />
                        <InputLabel className='text-[12px]'>Password</InputLabel>
                        <TextField
                            className='m-0 mb-10 bg-[#f6f6f6]'
                            required
                            fullWidth
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            variant="standard"
                            sx={{ '& .MuiInputBase-input': { padding: '7px 5px' } }}
                        />
                        <Grid className='mt-0' container>
                            <Grid item xs>
                                <label className='!text-[12px] text-[#DC2227]'>
                                    <input className='mr-10 bottom-[-2px] relative' type="checkbox" />
                                    Remember me
                                </label>
                            </Grid>
                            <Grid className='text-right' item xs>
                                <Link className='text-[12px] text-[#DC2227]' to="/request-password">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                        <Button
                            className="mt-20 mb-0 p-2 bg-[#DC2227] hover:bg-[#DC2227] text-white rounded-none capitalize"
                            type="submit"
                            fullWidth
                            variant="outlined"
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}