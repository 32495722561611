import { DefaultItem } from "@directus/sdk";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../context/app-context";
import { useDirectus } from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { AssetType, MetaphoreAssets } from "../../types/assets";
import { MetaphoreCategories } from "../../types/categories";
import { Colors } from "../../types/enums/colors";
import { Status } from "../../types/enums/status";
import { findRootCategoryById } from "../../utils";
import PreviewAsset from "../dialogs/preview-asset";
import Dropdawn from "../dropdawn/dropdawn";
import MediaTypeIcon from "../media-format/media-type-icon";

type Props = {
  classes?: string;
  draggable?: boolean;
  approval?: boolean;
  status?: boolean;
  isCheckbox?: boolean;
  isFavourite?: boolean;
  asset: DefaultItem<MetaphoreAssets>;
  offset: number;
  isCollection?: boolean;
  categories?: MetaphoreCategories[];
  getDradId?: number | undefined | string;
  actions?: React.ReactElement;
  setDragId?: React.Dispatch<React.SetStateAction<number | undefined | string>>;
};

export interface Resolution {
  width: number;
  height: number;
}

export default function CardBox(props: Props): React.ReactElement {
  const {
    classes,
    asset,
    offset,
    draggable,
    isCheckbox,
    isFavourite,
    isCollection,
    getDradId,
    actions,
    approval,
    status,
    categories,
    setDragId,
  } = props;

  const [type, setType] = useState<string | undefined>();
  const [isFullScreenAsset, setIsFullScreenAsset] = useState<boolean>(false);
  const [resolution, setResolution] = useState<Resolution>();
  const [stackVersions, setStackVersions] = React.useState<
    DefaultItem<MetaphoreAssets>[]
  >([]);
  const { category, setSelectedFiles, selectedFiles, assets, setAssets } =
    React.useContext(AppContext);
  const { directus } = useDirectus();
  const {
    setFavorite,
    getFileType,
    addAssetToStackVersion,
    getAssetStackVersions,
  } = Asset(directus);
  const isImageType =
    type === AssetType.webp ||
    type === AssetType.png ||
    type === AssetType.jpeg ||
    type === AssetType.jpg;

  React.useEffect(() => {
    (async (): Promise<void> => {
      const file = await getFileType(asset.image);

      setType(file?.type);
      setResolution({
        width: file?.width ?? 0,
        height: file?.height ?? 0,
      });
      setStackVersions((await getAssetStackVersions(asset.id)) ?? []);
    })();
  }, []);

  const handleSelectFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { id, checked } = event.target;

    setSelectedFiles([...selectedFiles, id]);

    if (!checked) {
      setSelectedFiles(selectedFiles.filter((item) => item !== id));
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
    if (setDragId) {
      setDragId(parseInt(event.currentTarget.id));
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    event.currentTarget.style.background = "";
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>): void => {
    event.currentTarget.style.background = "";
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (parseInt(event.currentTarget.id) != getDradId) {
      event.currentTarget.style.background = "#d3d3d366";
    }
  };

  const handleDrop = async (
    event: React.DragEvent<HTMLDivElement>,
    asset: DefaultItem<MetaphoreAssets>
  ): Promise<void> => {
    event.preventDefault();
    event.currentTarget.style.background = "";

    if (getDradId && getDradId !== asset.id) {
      await addAssetToStackVersion(asset.id, getDradId);
      setStackVersions((await getAssetStackVersions(asset.id)) ?? []);
      setAssets(assets?.filter((file) => file.id !== getDradId));
    }
  };

  const getStatus = (status: string | undefined): string => {
    switch (status) {
      case Status.Approved:
        return Colors.Approved;
      case Status.Archived:
        return Colors.Archived;
      case Status.Published:
        return Colors.Published;
      case Status.Rejected:
        return Colors.Rejected;
      case Status.Review:
        return Colors.Review;
      default:
        return Colors.Review;
    }
  };

  return (
    <Card
      id={asset.id}
      draggable={!stackVersions.length ? draggable : false}
      onDragStart={(e) => handleDragStart(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDragEnd={(e) => handleDragEnd(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e, asset)}
      style={{ borderColor: "#E8E8E8", boxShadow: "none" }}
      className={`
            ${!stackVersions.length ? "cursor-grabbing" : ""}
            shadow-none 
            min-h-[350px]
            rounded-[15px] 
            flex flex-col 
            border-solid 
            border-1 w-full 
            max-w-[calc(50%_-_15px)] 
            HD:max-w-[calc(25%_-_15px)] 
            HD+:max-w-[calc(20%_-_16px)] 
            QHD:max-w-[calc(10%_-_18px)] 
            relative
            on-hover
            ${classes}
        `}
    >
      <CardHeader
        avatar={
          <>
            {isCheckbox && (
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                key={asset.id}
                id={asset.id.toString()}
                onChange={handleSelectFile}
                value={asset.id}
                checked={selectedFiles.includes(asset.id.toString())}
              />
            )}
            {isFavourite && (
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFavorite(asset.id, event.target.checked)
                }
                defaultChecked={asset.favorite}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
              />
            )}
          </>
        }
        action={
          actions ?? (
            <Dropdawn
              classes="mt-6"
              asset={asset}
              fileType={type}
              resolution={resolution}
              stackVersions={stackVersions}
              setStackVersions={setStackVersions}
              setCollectionItem={isCollection}
            />
          )
        }
      />
      <div className="mx-[20px] h-full flex items-center justify-center relative">
        <Link
          to={`/file/${asset.id}/${offset}`}
          state={{
            stack: stackVersions.length ? [asset, ...stackVersions] : null,
          }}
        >
          <MediaTypeIcon
            asset={asset}
            classes="text-[120px] max-h-[200px] object-contain"
          />
        </Link>
        {isImageType && (
          <div
            onClick={() => setIsFullScreenAsset(true)}
            style={{ background: "#727070b3" }}
            className="absolute h-[30px] z-10 w-full left-0 bottom-0 text-white cursor-pointer text-center hidden hovered"
          >
            <VisibilityIcon />
          </div>
        )}
      </div>
      <CardContent className="p-[20px] leading-[17px] last:pb-[10px]">
        <Typography className="mb-[10px] text-[14px] break-keep max-h-[40px] overflow-hidden">
          {asset.name}
        </Typography>
        <Typography className="font-light border-[#4a4a4a] flex justify-between text-[14px]">
          <span>
            {asset.content_amount}
            {asset.unit} •{" "}
            {findRootCategoryById(asset?.category_id, categories || category)}
          </span>
          {!!stackVersions.length && (
            <span className="font-bold">v. {stackVersions.length + 1}</span>
          )}
        </Typography>
        {approval && (
          <Typography className="mb-[10px] text-[14px] break-keep text-right capitalize">
            <span
              style={{ background: getStatus(asset.approval) }}
              className={`w-[10px] h-[10px] color-[#000000] inline-block border-1 border-slate-600 mr-5 mb-[-1px] rounded-full`}
            />
            {asset.approval}
          </Typography>
        )}
        {status && (
          <div className="w-[80px] text-[14px] inline-block break-keep text-right capitalize top-[90px] right-[-25px] absolute rotate-[270deg] hidden hovered">
            {asset.status}
            <span
              style={{ background: getStatus(asset.status) }}
              className={`w-[10px] h-[10px] color-[#000000] inline-block border-1 border-slate-600 ml-[5px] rounded-full`}
            />
          </div>
        )}
      </CardContent>
      <PreviewAsset
        asset={asset}
        isOpen={isFullScreenAsset}
        onClose={() => setIsFullScreenAsset(false)}
      />
    </Card>
  );
}
