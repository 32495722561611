import { Box, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CardBox from "../../components/card/card-box";
import Search from "../../components/search/search";
import { AppContext } from "../../context/app-context";
import Navigation from "../../layout/navigation";
import { useDirectus } from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { Approvals } from "../../types/approvals";
import { Action } from '../../types/enums/action';
import { Status } from "../../types/enums/status";
import { downloadInternalFile } from "../../utils";

import ContextMenu from "./parts/context-menu/context-menu";
import Filter from "./parts/filter/filter";
import LeftNavigation from "./parts/left-navigation/left-navigation";

export default function ApprovalsAssets(): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);

    const { filter, sorting, assets, sessions, setAssets, setSessions } = useContext(AppContext);
    const { directus } = useDirectus();
    const { getSessions, updateAssetById } = Asset(directus);
    const state = useLocation().state;
    
    useEffect(() => {
        (async () => {
            setSessions(await getSessions(sorting, filter));
        })();
    }, [filter, sorting]);

    useEffect(() => {
        const session = sessions?.filter(item => item.id === state.id);
        if (session?.length) {
            setAssets(session[0].items);
        }
        
        setLoading(false);
    }, [loading, state, sessions]);

    const handleAction = async (id: string, action: string): Promise<void> => {
        try {
            switch(action) {
                case Action.Approve:
                    await updateAssetById(id, { approval: Status.Approved });
                    enqueueSnackbar('File was approved.', { variant: 'success' });
                    break;
                case Action.Reject:
                    await updateAssetById(id, { approval: Status.Rejected, status: Status.Draft });
                    enqueueSnackbar('File was rejected.', { variant: 'success' });
                    break;
                case Action.Publish:
                    await updateAssetById(id, { status: Status.Published });
                    enqueueSnackbar('File was published.', { variant: 'success' });
                    break;
                case Action.Archive:
                    await updateAssetById(id, { status: Status.Archived });
                    enqueueSnackbar('File was archived.', { variant: 'success' });
                    break;
                case Action.Review:
                    await updateAssetById(id, { approval: Status.Review, status: Status.Draft });
                    enqueueSnackbar('File is not reviewed.', { variant: 'success' });
                    break;
            }

            setSessions(await getSessions(sorting, filter));
        } catch(error) {
            // eslint-disable-next-line no-console
            console.log(error);
            enqueueSnackbar('Somthing was wrong.', { variant: 'error' });
        }
    }

    const handleDownload = (id: string): void => {
        const imageId = assets?.find(item => item.id === id)?.image;

        if (imageId) {
            downloadInternalFile(directus, imageId);
        }
    }

    return (
        <>
            <Navigation classes="!pl-[345px]">
                <Search label="Search files" />
            </Navigation>
            <div className="flex w-full flex-row gap-[25px]">
                <div className="basis-[320px] shrink-0">
                    <LeftNavigation name="Approvals" sessions={sessions} />
                </div>
                <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex content-start">
                    <div className="flex w-full justify-items-center h-fit">
                        <h2 className="text-[24px] text-rose-600">
                            Approval Session <span className="text-[16px] text-slate-400">{`(${assets?.length})`}</span>
                        </h2>
                    </div>
                    <Filter setLoading={setLoading} />
                    {loading ? (
                        <Box className="flex w-full justify-center">
                            <CircularProgress />
                        </Box>
                    ) : assets?.length ? assets.map((asset, index) => (
                        <CardBox 
                            key={asset.id}
                            isCheckbox
                            approval
                            status
                            asset={asset} 
                            offset={index + 1}
                            actions={
                                <ContextMenu 
                                    key={asset.id}
                                    id={asset.id} 
                                    status={asset.status}
                                    approval={asset.approval}
                                    actions={{
                                        approve: handleAction,
                                        reject: handleAction,
                                        download: handleDownload,
                                    }} 
                                />
                            }
                        />
                    )) : 'Nothing for approvals'}
                </div>
            </div>
        </>
    );
}