import { ChevronLeftOutlined, ChevronRightOutlined, DescriptionOutlined } from '@mui/icons-material';
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import React from 'react';

export default function Sidebar(): React.ReactElement {
    const [isOpen, setOpen] = React.useState<boolean>(false);

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            open={isOpen}
            PaperProps={{ className: `fixed transition-all overflow-hidden shadow border ${isOpen ? 'w-[120px]' : 'w-[50px]'}` }}
            className={`sticky z-50 overflow-hidden transition-all ${isOpen ? 'w-[120px]' : 'w-[50px]'}`}
        >
            <List>
                <ListItem disablePadding className='border-b'>
                    <ListItemButton onClick={() => setOpen(!isOpen)} className='min-h-[75px] justify-center'>
                        {isOpen ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className='border-b justify-center'>
                    <ListItemButton
                        className={`relative min-h-[75px] flex-col items-center justify-center transition-all ${isOpen ? 'w-100' : 'w-0'}`}
                    >
                        <ListItemIcon className="justify-center fill-company [&>svg]:fill-company">
                            <DescriptionOutlined className='text-[22px]' />
                        </ListItemIcon>
                        <ListItemText className={`flex-none [&>span]:text-[14px] overflow-hidden text-center text-lg transition-all ${isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0'} text-company pl-3`}>
                            Assets
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}
