import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
};

export default function Title(props: Props): ReactElement {
    const { children } = props;

    return (
        <h2 className="text-[14px] font-light uppercase text-slate-400 mb-5">
            { children }
        </h2>
    );
}

Title.defaultProps = {
    children: undefined,
};
