import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    classes?: string;
};

export default function Navigation(props: Props): ReactElement {
    const { children, classes } = props;
    
    return <div className={`flex w-full mb-[25px] gap-[25px] pl-[295px] ${classes}`}>{children}</div>;
}

Navigation.defaultProps = {
    children: undefined,
};
