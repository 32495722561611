import React, { useContext } from "react";
import { useCookies } from "react-cookie";

import { AppContext } from "../../../../context/app-context";
import { ListLayout } from "../../../files/files";
import CollectionCard from "../collection-card/collection-card";
import CollectionList from "../collection-list/collection-list";
import CollectionListItem from "../collection-list/collection-list-item";
import CollectionFilter from "../filter/filter";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SharedCollections(props: Props): React.ReactElement {
  const { setLoading } = props;

  const { sharedCollections } = useContext(AppContext);
  const [cookies] = useCookies();

  return (
    <>
      <div className="flex w-full justify-between justify-items-center h-fit mt-[50px]">
        <h2 className="text-[24px] text-rose-600">
          Collections shared with you
          <span className="text-[16px] text-slate-400">
            {" "}
            ({sharedCollections?.length ?? 0})
          </span>
        </h2>
      </div>
      <CollectionFilter />
      {cookies.listLayout === ListLayout.Grid ? (
        sharedCollections?.map((collection) => (
          <CollectionCard
            key={collection.id}
            collection={collection}
            setLoading={setLoading}
          />
        ))
      ) : (
        <CollectionList>
          {sharedCollections?.map((collection) => (
            <CollectionListItem
              key={collection.id}
              collection={collection}
              setLoading={setLoading}
            />
          ))}
        </CollectionList>
      )}
      {!sharedCollections?.length && (
        <div className="text-[24px] text-neutral-400">
          <div>No collection has been shared with you yet.</div>
          <span className="text-[16px]">
            Team members can invite you to their collection.
          </span>
        </div>
      )}
    </>
  );
}
